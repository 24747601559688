import {
    _
} from 'wgnet-awesomejs';
import { useSimpleFlow } from 'registration/js/lib/get_splittest_flow';

import {
    PASSWORD_FLOW_SETTING,
    NAME_FLOW_SETTING
} from '../base/constants';

function getNameFlow() {
    if (useSimpleFlow()) {
        return null;
    }
    const nameFlow = _.extract(NAME_FLOW_SETTING, 'default');
    return nameFlow;
}

function getPasswordFlow() {
    if (useSimpleFlow()) {
        return 'generate';
    }
    const passwordFlow = _.extract(PASSWORD_FLOW_SETTING, 'default');
    return passwordFlow;
}

export {
    getNameFlow,
    getPasswordFlow
};
