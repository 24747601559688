import Application, {
    _, $
} from 'wgnet-awesomejs';
import BaseInput from './input';

import {
    FIELD_ERROR_EVENTS
} from './error';

import {
    EXTERNAL_CANCEL_EVENT,
    EMAIL_SETTING,
} from '../constants';

const prefilledEmail = () => _.extract(EMAIL_SETTING, false);

const BaseLoginField = BaseInput.extend({
    name: 'login-field',
    fieldName: 'login',
    template() {
        return '<input type="text" name="login" class="js-input">';
    },
    beforeInitialize(...args) {
        this.prefilledEmail = prefilledEmail();
        BaseInput.prototype.beforeInitialize.apply(this, args);
    },
    afterInitialize(...args) {
        BaseInput.prototype.afterInitialize.apply(this, args);
        this.$editButton = this.$el.find('.js-login-edit');
        this.$editButton.on('click', _.bind(this.onEditEnable, this));
        this.delegateEvents({
            'keydown input': 'clearValue',
            'paste input': 'clearValue'
        });
        if (this.prefilledEmail) {
            Application.once(FIELD_ERROR_EVENTS.login, _.bind(this.onEditEnable, this));
            this.trigger('login.valid');
        }
        Application.on(EXTERNAL_CANCEL_EVENT, _.bind(this.onEditEnable, this));
    },
    clearValue(event) {
        const key = event.which || event.keyCode;

        if (key === 32) {
            event.preventDefault();
            return false;
        }

        if (event.type === 'paste') {
            setTimeout(() => {
                const type = $(event.currentTarget).attr('type');
                $(event.currentTarget).attr('type', 'text');
                $(event.currentTarget).attr('type', type);
            }, 0);
        }
        return true;
    },
    onEditEnable() {
        this.hideEditButton();
        this.enableLoginField();
    },
    getContext(...args) {
        const result = BaseInput.prototype.getContext.apply(this, args);

        if (this.prefilledEmail) {
            result.push({
                'data-email': this.prefilledEmail,
            });
            this.trigger('login.edit.disabled');
        }
        return result;
    },
    hideEditButton() {
        this.$editButton.hide();
    },
    enableLoginField() {
        this.$el.removeClass('readonly');
        this.$input.removeAttr('readonly').focus();
        this.trigger('login.edit.enabled');
    }
});

export default BaseLoginField;
