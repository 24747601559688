import $ from 'jquery';
import Application, {
    _
} from 'wgnet-awesomejs';
import BaseView from 'wgnet-awesomejs/base/view';
import 'jquery-ui/ui/widgets/dialog';
import Spinner from 'registration/js/lib/spinner';
import { GTM_EVENT_TYPES } from 'registration/js/base/fields/error';


import {
    loginRequiredValidator,
    emailPatternValidator
} from 'registration/js/lib/validators';

const BASE_FORM_LOGIN_ERRORS = [
    'conflict_login',
    'in_stoplist'
];

const INVALID_LOGIN_ERROR = 'invalid';
const EMPTY_LOGIN_ERROR = 'error_required';

const BaseSuggestionView = BaseView.extend({
    spinnerOptions: {
        modifiers: ['suggest']
    },
    dialogSettings: {},
    defaultSettings: {
        autoOpen: false,
        resizable: false,
        height: 'auto',
        minHeight: 'auto',
        modal: true,
        closeOnEscape: false
    },
    templates: {
        question() {},
        edit() {}
    },
    templatesEvents: {
        question: {
            'click .js-dialog-continue': 'confirmEmail',
            'click .js-dialog-cancel': 'editEmail'
        },
        edit: {
            'submit .js-dialog-form': 'confirmEditEmail',
            'focus input': 'focusInput',
            'blur input': 'blurInput'
        }
    },
    initialize(options = {}) {
        this.parent = options.parent;
        this.$body = $('body');
        this.$window = $(window);
        this.$mainBlock = $('.js-main');
        this.dataLayer = window.dataLayer;

        this.dialogSettings = _.defaults(this.defaultSettings, this.dialogSettings);
        this.$el.dialog(this.dialogSettings);
        this.$el.dialog('widget').find('.ui-dialog-titlebar').hide();
        this.clickOnOverlayToClose = false;

        Application.on('closesuggestdialog', this.close, this);
    },
    close() {
        this.stopSpin();
        this.undelegateEvents();

        this.$el.dialog('close');

        this.$window.unbind('deviceorientation resize');

        return false;
    },
    initCloseClickOnOverlay() {
        $('.ui-widget-overlay').on('click', _.bind(this.close, this));
    },
    show(data = {}) {
        this.suggestion = _.first(data.suggestions.domains) || {};
        this.$el.dialog('option', 'width', this.dialogSettings.width);
        this.$el.dialog('option', 'position', this.dialogSettings.position);
        this.$el.dialog('option', 'modal', true);
        this.update('question', {
            email: this.suggestion.full
        });
        this.$el.dialog('open');
        if (typeof this.dataLayer !== 'undefined') {
            this.dataLayer.push({
                event: 'GAevent',
                eventLabel: 'login_suggested',
                eventCategory: 'Form messages',
                eventAction: GTM_EVENT_TYPES.tipEmailPopupShow
            });
        }
        // initCloseClickOnOverlay call should go after showing dialog.
        if (this.clickOnOverlayToClose) {
            this.initCloseClickOnOverlay();
        }
        return false;
    },
    update(templateName, variables) {
        this.$el.html(this.templates[templateName](variables));
        this.undelegateEvents();
        this.delegateEvents(this.templatesEvents[templateName]);
    },
    updatePopupPosition() {
        const $popupParent = this.$el.parent();

        // popup should position in the top of the page
        $popupParent.css({
            top: '0px',
            width: `${this.$body.width()}px`
        });

        // overlay should have height of the page
        // which is equal to height of the main block
        $('.ui-widget-overlay').css({
            position: 'absolute',
            top: '0px',
            left: '0px',
            height: this.$mainBlock.height()
        });

        this.$body.get(0).scrollIntoView(true); // scroll to the top of the page
    },
    confirmEmail() {
        this.continueRegistration(this.suggestion.full);
        if (typeof this.dataLayer !== 'undefined') {
            this.dataLayer.push({
                event: 'GAevent',
                eventLabel: 'login_suggested',
                eventCategory: 'Form messages',
                eventAction: GTM_EVENT_TYPES.tipEmailPopupConfirm
            });
        }
        return false;
    },
    confirmEditEmail() {
        const login = this.$('.js-dialog-email').val();
        if (loginRequiredValidator({ login })) {
            this.renderError(`Settings.errors.registration-login-${EMPTY_LOGIN_ERROR}`);
            return false;
        }
        if (emailPatternValidator({ login })) {
            this.renderError(`Settings.errors.registration-login-${INVALID_LOGIN_ERROR}`);
            return false;
        }

        this.continueRegistration(login);
        if (typeof this.dataLayer !== 'undefined') {
            this.dataLayer.push({
                event: 'GAevent',
                eventLabel: 'login_suggested',
                eventCategory: 'Form messages',
                eventAction: GTM_EVENT_TYPES.tipEmailPopupCancel
            });
        }
        return false;
    },
    continueRegistration(email) {
        this.startSpin();

        // it will be called if model has validation errors
        // and strictRegistrationStart promise never resolved
        const handleModelInvalid = () => {
            this.close();
        };
        this.parent.model.once('invalid', handleModelInvalid);

        this.parent.strictRegistrationStart(
            email, this.suggestion.domain
        ).catch((...args) => this.onRegistrationError(...args)).finally(() => {
            this.parent.model.off('invalid', handleModelInvalid);
        });
        return false;
    },
    /* eslint dot-notation: ["error", { "allowPattern": "^__all__$" }] */
    onRegistrationError(result = {}) {
        try {
            result.responseJSON = result.responseJSON || JSON.parse(result.responseText);
        } catch (e) {
            result.responseJSON = undefined;
        }
        const errors = (result.responseJSON ? result.responseJSON.errors : result) || {};

        const allError = (errors['__all__'] || [])[0];
        const loginError = (errors.login || [])[0];
        const bonusCodeError = (errors.bonus_code || [])[0];
        const powError = (errors.pow || [])[0];
        const captchaError = (errors.captcha || [])[0];
        const parentalEmailError = (errors.parental_email || [])[0];
        let login = this.$('.js-dialog-email').val();
        if (login === '') {
            login = this.suggestion.full;
        }
        if (loginError) {
            const challenge = this.parent.model.activeChallengeType;
            if (_.contains(BASE_FORM_LOGIN_ERRORS, loginError) || challenge === 'captcha') {
                const errorEvent = {
                    errors: [loginError],
                    field: 'login',
                    value: login
                };
                this.parent.$form.trigger('login.error', errorEvent);
                this.parent.onRegistrationError(result);
                this.close();
            } else if (loginError === INVALID_LOGIN_ERROR) {
                this.update('edit', {
                    email: this.suggestion.full
                });
                this.renderError(`Settings.errors.registration-login-${loginError}`);
            } else {
                this.parent.onRegistrationError({
                    responseJSON: {
                        errors: {
                            login: [loginError]
                        }
                    }
                });
                this.close();
            }
        } else if (bonusCodeError || powError || captchaError || parentalEmailError) {
            const errorEvent = {
                errors: [],
                field: 'login',
                value: login
            };
            this.parent.$form.trigger('login.error', errorEvent);
            this.parent.onRegistrationError(result);
            this.close();
        } else if (allError) {
            if (allError === 'empty_field') {
                this.renderError(`Settings.errors.registration-${allError}`);
            } else {
                this.parent.onRegistrationError(result);
                this.close();
            }
        } else {
            this.parent.onRegistrationError({
                responseJSON: {
                    errors: {
                        __all__: ['no_connection']
                    }
                }
            });
            this.close();
        }
        this.stopSpin();
    },
    renderError(key) {
        const $error = this.$('.js-form-field-error-content');
        $error.html(_.extract(key)).removeClass('hidden');
        this.$('.js-dialog-email').focus();
    },
    startSpin() {
        const $el = this.$('.js-throbbing-element');
        this.spinner = this.spinner || new Spinner(this.spinnerOptions);
        this.spinner.spin($el[0]);
    },
    stopSpin() {
        if (this.spinner) {
            this.spinner.stop();
        }
    },
    editEmail() {
        const offset = this.$el.offset();
        const $window = $(window);

        if (!this.parent.isWaiting) {
            const left = offset.left - $window.scrollLeft();
            const top = offset.top - $window.scrollTop();

            this.$el.dialog('option', 'position', [left, top]);
            this.$el.dialog('option', 'width', this.$el.outerWidth());
            this.update('edit', {
                email: this.suggestion.full
            });
        }

        return false;
    },
    focusInput(event) {
        const $fieldset = $(event.target).parents('fieldset').first();

        $fieldset.addClass('active');
    },
    blurInput(event) {
        const $fieldset = $(event.target).parents('fieldset').first();

        $fieldset.removeClass('active');
    }
});

export default BaseSuggestionView;
