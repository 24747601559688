import { getPasswordFlow } from 'registration/js/lib/get_field_flow';
import BaseInput from './input';

const BaseRepasswordField = BaseInput.extend({
    name: 'repassword-field',
    fieldName: 'repassword',
    afterInitialize(...args) {
        BaseInput.prototype.afterInitialize.apply(this, args);
        const passwordFlow = getPasswordFlow();

        if (passwordFlow !== 'default') {
            this.$el.hide();
        }
    }
});

export default BaseRepasswordField;
