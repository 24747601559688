const localStorage = window.localStorage;

const emitStorageKeyError = () => console.error('Storage key must be defined on initialization or method call');

function get(key) {
    let storageKey = this.key;

    if (key) {
        storageKey = key;
    }

    if (!storageKey) {
        return emitStorageKeyError();
    }

    if (!localStorage) {
        return null;
    }

    const storageValue = localStorage.getItem(storageKey);

    let data;

    try {
        data = JSON.parse(storageValue);
    } catch (err) {
        data = null;
    }

    return data;
}

function set(data, key) {
    const storageKey = key || this.key;

    if (!storageKey) {
        emitStorageKeyError();
        return;
    }

    const storageValue = JSON.stringify(data);

    localStorage.setItem(storageKey, storageValue);
}

function clear(key) {
    const storageKey = key || this.key;

    if (!storageKey) {
        emitStorageKeyError();
        return;
    }

    localStorage.removeItem(storageKey);
}

function createStorageManager(key) {
    return {
        key,
        clear,
        get,
        set,
    };
}

export default createStorageManager;
