import $ from 'jquery';

import BaseView from 'wgnet-awesomejs/base/view';
import Spinner from 'registration/js/lib/spinner';


let waitingState = null;

const BaseWaitingView = BaseView.extend({
    waitingContainerSelector: '.js-main',
    appContainerSelector: '.js-content',
    hiddenClass: 'hidden',
    spinnerModifiers: [],

    beforeInitialize() {
        this.appContainer = document.querySelector(this.appContainerSelector);
        this.spinner = new Spinner({
            modifiers: this.spinnerModifiers,
        });
        this.appContainer.classList.add(this.hiddenClass);

        const container = document.querySelector(this.waitingContainerSelector);
        this.$el = $(document.createElement('div'));
        container.appendChild(this.$el[0]);
    },

    render() {
        this.spinner.spin(this.$el);
    },

    remove() {
        this.spinner.stop();
        this.appContainer.classList.remove(this.hiddenClass);
        BaseView.prototype.remove.apply(this);
    }
});

BaseWaitingView.stopWaiting = () => {
    const timerId = waitingState && waitingState.timerId;
    const view = waitingState && waitingState.view;
    if (timerId) {
        clearTimeout(timerId);
    }
    if (view) {
        view.remove();
    }
    waitingState = null;
};

BaseWaitingView.startWaiting = (WaitingView, { waitingTimeout = 5000 } = {}) => {
    WaitingView.stopWaiting();

    const view = new WaitingView();
    view.render();

    const timerId = setTimeout(() => {
        waitingState.timerId = null;
        WaitingView.stopWaiting();
    }, waitingTimeout);

    waitingState = {
        view,
        timerId,
    };
};

export default BaseWaitingView;
