export const BONUS_CODE_MODE = {
    hidden: 'hidden',
    open: 'open',
};

const privateState = new WeakMap();

class BonusCodeOptions {

    constructor({
        mode, max_length: maxLength, value: prepopulatedValue, required, regexp,
    } = {}, { mobile = false } = {}) {
        let visible = mode !== BONUS_CODE_MODE.hidden;
        let opened = mode === BONUS_CODE_MODE.open;
        const isPrepopulated = Boolean(prepopulatedValue);

        if (mobile && !required && !isPrepopulated) {
            visible = false;
            opened = false;
        }

        privateState.set(this, {
            required: Boolean(required),
            prepopulatedValue: prepopulatedValue || undefined,
            regexp: new RegExp(regexp),
            visible,
            opened,
            maxLength,
        });
    }

    /* getters */

    getPrepopulatedValue() {
        return privateState.get(this).prepopulatedValue;
    }

    getMaxLength() {
        return privateState.get(this).maxLength;
    }

    getRegExp() {
        return privateState.get(this).regexp;
    }

    isRequired() {
        return privateState.get(this).required;
    }

    isVisible() {
        return privateState.get(this).visible;
    }

    isOpened() {
        return privateState.get(this).opened;
    }

    isPrepopulated() {
        return Boolean(this.getPrepopulatedValue());
    }

    isReadOnly() {
        return this.isPrepopulated();
    }


    /* mutations */

    removeReadOnlyState() {
        return privateState.get(this).prepopulatedValue = undefined;
    }

    setOpened(opened) {
        privateState.get(this).opened = Boolean(opened);
    }
}


export default BonusCodeOptions;
