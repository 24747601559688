const _cached = new WeakMap();
const _empty = Symbol('empty');
export function cached(argsToCacheKey) {
    const cachedWrapper = (methodName, method) => {
        return function (...args) {
            let cacheMap;
            if (_cached.has(this)) {
                cacheMap = _cached.get(this);
            }
            else {
                cacheMap = new Map();
                _cached.set(this, cacheMap);
            }
            const cacheKey = methodName + ((argsToCacheKey && argsToCacheKey(...args)) || '');
            let result = _empty;
            if (cacheMap.has(cacheKey)) {
                result = cacheMap.get(cacheKey);
            }
            if (result === _empty) {
                result = method.call(this, ...args);
                cacheMap.set(cacheKey, result);
            }
            return result;
        };
    };
    return function cachedDecorator(target, prop, descriptor) {
        descriptor.value = cachedWrapper(prop, descriptor.value);
        return descriptor;
    };
}
