import $ from 'jquery';
import BaseComponent from 'wgnet-awesomejs/base/component';
import pushGaEvent from 'registration/js/lib/googletagmanager/events';
import pushGaEventNew, { GTM_EVENTS } from 'registration/js/lib/googletagmanager/new_events';
import { showLoginForm } from 'registration/js/lib/get_splittest_flow';
import 'registration/js/lib/linked_label_hover';

const BaseEulaField = BaseComponent.extend({
    name: 'eula-field',
    fieldName: 'eula',
    template() {
        return '<input name="eula" type="checkbox"/>';
    },
    afterInitialize() {
        this.delegateEvents({
            'change input': 'onChange'
        });
    },
    onChange(event) {
        const checkbox = event.currentTarget;
        const { name, checked } = checkbox;
        const newCheckboxValue = checked ? 'checked' : 'unchecked';

        if (showLoginForm()) {
            pushGaEvent('REG_FORM_INTERACTION', name, newCheckboxValue);
        }

        pushGaEventNew({
            event: GTM_EVENTS.REG_FORM_CHECKBOX_CLICK,
            label: [name, newCheckboxValue]
        });
    },
    render(...args) {
        const $rendered = $(BaseComponent.prototype.render.call(this, args));
        this.$el.replaceWith($rendered);
        this.$el = $rendered;
        this.el = $rendered[0];
        return $rendered;
    }
});

export default BaseEulaField;
