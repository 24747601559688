import $ from 'jquery';

const spinnerTemplate = new Array(12).fill(
    `<span class="throbber_item">
        <span class="throbber_line"></span>
    </span>`
).join('');

class WgnSpinner {
    static template(modifiers = []) {
        const modifierClass = modifiers.map(m => `throbber__${m}`).join(' ');
        return `<span class="throbber ${modifierClass}">
            ${spinnerTemplate}
        </span>`;
    }

    constructor(options = {}) {
        const {
            modifiers
        } = options;

        this.$el = $(WgnSpinner.template(modifiers));
        if (options.container) {
            this.$container = $(options.container);
        }
    }

    spin(container) {
        const $container = this.$container || $(container);
        $container.append(this.$el);
    }

    stop() {
        this.$el.remove();
    }
}

export default WgnSpinner;
