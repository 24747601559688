import $ from 'jquery';
import {
    _
} from 'wgnet-awesomejs';
import mobile from 'wgnet-awesomejs/lib/mobile';
import URI from 'urijs';
import { useSimpleFlow } from 'registration/js/lib/get_splittest_flow';
import ChallengeModel from 'wgnet-awesomejs/base/challenge_model';

import { bonusCodeOptions, countryCodeOptions } from 'registration/js/lib/fieldsOptions';

import {
    PASSWORD_FLOW_SETTING,
    NAME_FLOW_SETTING
} from './constants';

const regOptions = _.extract('Settings.Registration.registrationOptions', {});

$.ajaxSetup({
    traditional: true
});

const Model = ChallengeModel.extend({
    RiddlerLibPath: `${_.extract('Settings.STATIC_URL')}registration/js/riddler/`,
    buildUrl() {
        const url = ChallengeModel.prototype.buildUrl.call(this);
        if (window.location.search) {
            const query = URI.parseQuery(window.location.search);
            // deleting query tid from form POST registration url
            delete query.tid;
            url.addQuery(query);
        }

        if (useSimpleFlow()) {
            url.pathname(_.extract('Settings.Registration.newSimpleUrl'));
            url.addQuery({ external: _.extract('Settings.Registration.externalProvider.code') });
        }

        return url;
    },
    validate(attrs, options = {}) {
        options.activeChallengeType = this.activeChallengeType;
        const validationErrors = ChallengeModel.prototype.validate.call(this, attrs, options);
        if (options.presaveValidate && validationErrors && validationErrors.strict_login) {
            const keys = _.keys(validationErrors);
            let hasError;
            let hasStrict;
            for (let i = keys.length - 1; i >= 0; i -= 1) {
                const error = validationErrors[keys[i]];
                if (keys[i] === 'strict_login' && !options.ignoreLoginStrict) {
                    hasStrict = true;
                } else if (_.isString(error[0])) {
                    hasError = true;
                }
            }
            if (hasError) {
                return validationErrors;
            }
            if (hasStrict) {
                const nameError = (validationErrors.name || [])[0];

                if (_.isObject(nameError)) {
                    nameError.then((result = {}) => {
                        if (!result.error) {
                            this.trigger('login.strict.error', validationErrors.strict_login);
                        }
                    });
                } else {
                    this.trigger('login.strict.error', validationErrors.strict_login);
                }
                return validationErrors;
            }
            return false;
        }
        return validationErrors;
    },
    save(...args) {
        this.set('form_token', _.extract('Settings.Registration.formTokenRaw'));
        this.set('password_flow', _.extract(PASSWORD_FLOW_SETTING));
        this.set('name_flow', _.extract(NAME_FLOW_SETTING));

        if (bonusCodeOptions.isPrepopulated()) {
            this.set('bonus_code', bonusCodeOptions.getPrepopulatedValue());
        }
        this.set('feconf', JSON.stringify({
            form_type: mobile().useMobile ? 'mobile' : 'desktop',
            form_width: window.innerWidth,
            form_height: window.innerHeight
        }));
        if (countryCodeOptions.userCountryAllowed) {
            this.set('country_code', countryCodeOptions.userCountryCode);
        }
        return ChallengeModel.prototype.save.call(this, ...args);
    },
    set(attrs, options) {
        if (_.isObject(attrs) && !_.isEmpty(attrs)) {
            if (!attrs.eula) {
                attrs.eula = false;
            }
            if (attrs.login) {
                attrs.login = $.trim(attrs.login);
            }
            if (attrs.name) {
                attrs.name = $.trim(attrs.name);
            }
            attrs.consents = [].concat(
                attrs.eula ? regOptions.consentsImplicitList : [],
                attrs.subscr ? regOptions.consentsExplicitList : []
            );
        }
        return ChallengeModel.prototype.set.call(this, attrs, options);
    }
});

export default Model;
