import URI from 'urijs';
import {
    _
} from 'wgnet-awesomejs';
import { cached } from './decorators';

let currentFlow;

const getQuery = cached(() => URI.parseQuery(window.location.search));

const VERIFICATION_PROVIDERS = _.extract('Settings.Registration.externalServices.verificationProviders', []);
const INCOMPLETE_PROVIDERS = _.extract('Settings.Registration.externalServices.incompleteProviders', []);

function getSplitTestFlow() {
    if (currentFlow) {
        return currentFlow;
    }
    const enabled = _.extract('Settings.Registration.splitTestFlowEnabled', false);
    const defaultFlow = _.extract('Settings.Registration.splitTestFlowDefault');
    if (enabled) {
        currentFlow = getQuery().split_flow || defaultFlow;
    } else {
        currentFlow = defaultFlow;
    }
    return currentFlow;
}

function getSimpleFlow() {
    return Boolean(_.extract('Settings.Registration.simpleFlowEnabled'));
}

function useSimpleFlow() {
    if (_.extract('Settings.Registration.externalSigned') !== 1) {
        return false;
    }

    const currentProviderCode = _.extract('Settings.Registration.externalProvider.code');

    if (VERIFICATION_PROVIDERS.includes(currentProviderCode)) {
        return false;
    }

    return getSimpleFlow();
}

function useIncompleteFlow() {
    if (!useSimpleFlow()) {
        return false;
    }

    const currentProviderCode = _.extract('Settings.Registration.externalProvider.code');

    if (INCOMPLETE_PROVIDERS.includes(currentProviderCode)) {
        return true;
    }

    const incompleteFlow = getQuery().incomplete_flow;
    if (incompleteFlow === undefined) {
        return _.extract('Settings.Registration.incompleteFlowDefault');
    }
    return incompleteFlow === 'enabled';
}

const showLoginForm = cached(() => {
    const loginFormModeQuery = getQuery().login_form;
    const loginFormMode = _.extract('Settings.Signin.formMode', 'query');
    const loginFormEnabled = _.extract('Settings.Signin.enabled', false);
    const gameModificatorIsWows = _.extract('Settings.Registration.gameModificatorIsWows', false);

    if (!loginFormEnabled) {
        return false;
    }

    if ((loginFormMode === undefined) || (loginFormMode === 'query')) {
        return loginFormModeQuery === 'enabled';
    }

    if (gameModificatorIsWows && loginFormModeQuery === 'disabled') {
        return false;
    }

    return loginFormMode === 'open';
});

const openLoginOnInit = cached(() => getQuery().active_form === 'login');

export default getSplitTestFlow;
export {
    getQuery,
    getSimpleFlow,
    useIncompleteFlow,
    useSimpleFlow,
    showLoginForm,
    openLoginOnInit
};
