import Application, {
    _
} from 'wgnet-awesomejs';
import pushGaEvent from 'registration/js/lib/googletagmanager/events';
import pushGaEventNew, { GTM_EVENTS } from 'registration/js/lib/googletagmanager/new_events';
import { showLoginForm } from 'registration/js/lib/get_splittest_flow';
import { bonusCodeOptions } from 'registration/js/lib/fieldsOptions';

import BaseInput from './input';

import {
    FIELD_ERROR_EVENTS
} from './error';

const BaseBonusCode = BaseInput.extend({
    name: 'bonus-code',
    fieldName: 'bonus_code',
    state: bonusCodeOptions,

    afterInitialize(...args) {
        BaseInput.prototype.afterInitialize.apply(this, args);
        this.delegateEvents({
            'click .js-link': 'toggleField'
        });
        this.$form.on('bonus_code.error', _.bind(this.openField, this));
        this.$toggleBlock = this.$('.js-field-toogle-block');

        if (this.state.isReadOnly()) {
            Application.once(FIELD_ERROR_EVENTS.bonus_code, _.bind(this.enableEditField, this));
        }
    },
    enableEditField() {
        this.$fieldset.removeClass('readonly');
        this.$input.removeAttr('readonly').focus();
        this.state.removeReadOnlyState();
    },
    openField(event, options = {}) {
        if (options.prevalidate) {
            return;
        }
        this.setOpened(true);
    },
    toggleField(event) {
        event.preventDefault();
        const isOpened = this.state.isOpened();

        pushGaEventNew({
            event: GTM_EVENTS.INVITE_CODE_INTERACTION,
            label: isOpened ? 'hide' : 'show'
        });

        if (!isOpened) {
            if (showLoginForm()) {
                pushGaEvent('INVITE_CODE_INTERACTION', 'click');
            }
        }
        this.setOpened(!isOpened);
        return false;
    },

    setOpened(opened) {
        this.$toggleBlock.toggle(opened);
        this.state.setOpened(opened);

        if (opened) {
            // focus $input after it has been shown
            this.$input.focus();
        }
    },

    getContext(context = {}) {
        context.bonus_field = {
            hidden: !this.state.isVisible(),
            open: this.state.isOpened(),
            required: this.state.isRequired(),
            readonly: this.state.isReadOnly(),
            value: this.state.getPrepopulatedValue(),
            max_length: this.state.getMaxLength(),
        };
        return BaseInput.prototype.getContext.call(this, context);
    }
});

export default BaseBonusCode;
