import {
    _
} from 'wgnet-awesomejs';
import mailcheck from 'wgnet-awesomejs/lib/clientvalidation/mailcheck.custom';
import verifiedDomains from 'wgnet-awesomejs/lib/clientvalidation/verifieddomains';
import ApiNicknameValidationRequest from 'wgnet-awesomejs/lib/clientvalidation/nickname_validation';

import { bonusCodeOptions } from 'registration/js/lib/fieldsOptions';
import MinorService from './services/MinorsService';


const ERR_EMPTY_FIELD = 'empty_field';
const ERR_MIN_LENGTH = 'min_length';
const ERR_MAX_LENGTH = 'max_length';
const ERR_INVALID = 'invalid';
const ERR_SERVER = 'server_error';
const ERR_UNKNOWN = 'error_unknown';

const ERR_LOGIN_IN_STOPLIST = 'in_stoplist';
const ERR_LOGIN_EXIST = 'conflict_login';
const ERR_DISABLED_DOMAIN = 'disabled_domain';
const CACHE_LOGIN_ERRORS = [ERR_LOGIN_IN_STOPLIST, ERR_LOGIN_EXIST];

const ERR_REQUIRED_FIELD = 'required';
const ERR_EULA_REQUIRED = 'error_eula_required';
const ERR_CAPTCHA_REQUIRED = 'required';
const ERR_PASSWORD_MATCHES_LOGIN = 'error_password_matches_login';
const ERR_PASSWORD_MATCHES_NAME = 'error_password_matches_name';
const ERR_RE_PASSWORD_MISMATCH = 'error_re_password_mismatch';
const ERR_NAME_BANNED = 'banned';
const ERR_NAME_EXIST = 'already_exist';
const ERR_COUNTRY_CONFLICT = 'conflict_country';
const ACCOUNT_NAME_SETTING = 'Settings.Registration.personalLocationValidateAccountName';
const fieldOptions = _.extract('Settings.Registration.fields', {});


/* Login field validators */
const loginOptions = fieldOptions.login || {};
const emailRegexp = new RegExp(loginOptions.regexp);

const loginRequiredValidator = (attrs = {}, options = {}) => {
    if (options.prevalidate) {
        return false;
    }
    if (!attrs.login || attrs.login.length === 0) {
        return { __all__: [ERR_EMPTY_FIELD] };
    }
    return false;
};

const loginMinLengthValidator = (attrs = {}) => {
    if (attrs.login && attrs.login.length < loginOptions.min_length) {
        return { login: [ERR_MIN_LENGTH] };
    }
    return false;
};

const loginMaxLengthValidator = (attrs = {}) => {
    if (attrs.login && attrs.login.length > loginOptions.max_length) {
        return { login: [ERR_MAX_LENGTH] };
    }
    return false;
};

// eslint-disable-next-line no-unused-vars
const loginDisabledDomainsValidator = (attrs = {}, options = {}, errors = {}) => {
    const loginDisabledDomains = loginOptions.disabled_domains || [];

    if (!loginDisabledDomains || errors.login || !attrs.login) {
        return false;
    }
    const domain = attrs.login.split('@')[1];
    if (!domain) {
        return false;
    }
    const normalizedDomain = domain.toLowerCase();
    if (loginDisabledDomains.indexOf(normalizedDomain) > -1) {
        return { login: [ERR_DISABLED_DOMAIN] };
    }
    return false;
};

const emailPatternValidator = (attrs = {}, options = {}, errors = {}) => {
    const DEFAULT_FIELD_NAME = 'login';
    const fieldName = options.redefinedFieldName || DEFAULT_FIELD_NAME;

    if (errors[fieldName]) {
        return false;
    }

    if (attrs[fieldName] && !emailRegexp.test(attrs[fieldName])) {
        return { [fieldName]: [ERR_INVALID] };
    }
    return false;
};
const cacheLoginValidation = {};

const loginEmailDomainCheckValidator = (attrs = {}, options = {}, errors = {}) => {
    if (errors.login) {
        return false;
    }
    const loginHintObject = cacheLoginValidation[attrs.login];

    if (options.presaveValidate) {
        if (loginHintObject && loginHintObject.suggestions.type === 'hard') {
            const suggestedPromise = new Promise((resolve) => {
                resolve(cacheLoginValidation[attrs.login]);
            });
            return {
                strict_login: loginHintObject,
                login: [suggestedPromise]
            };
        }
        return false;
    }
    if (loginHintObject) {
        const suggestedPromise = new Promise((resolve) => {
            resolve(cacheLoginValidation[attrs.login]);
        });
        return {
            strict_login: loginHintObject,
            login: [suggestedPromise]
        };
    }
    if (attrs.login) {
        const promise = new Promise((resolve) => {
            mailcheck.run({
                email: attrs.login,
                domains: verifiedDomains,
                suggested: (suggestion) => {
                    const domains = suggestion.domains;
                    const loginDisabledDomains = loginOptions.disabled_domains || [];
                    _.map(domains, domain => {
                        domain.restricted = loginDisabledDomains.indexOf(domain.domain) > -1;
                    });
                    if (domains.length === 1) {
                        suggestion.type = domains[0].restricted ? 'soft' : 'hard';
                    }
                    cacheLoginValidation[attrs.login] = {
                        suggestions: suggestion,
                        field: 'login',
                        value: attrs.login
                    };
                    resolve(cacheLoginValidation[attrs.login]);
                },
                empty: () => {
                    resolve({});
                }
            });
        });
        return { login: [promise] };
    }
    return false;
};

const loginServerErrorsCache = [];
// eslint-disable-next-line no-unused-vars
const loginServerCacheValidator = (attrs = {}, options = {}, errors = {}) => {
    if (errors.login) {
        return false;
    }
    const cacheError = _.find(loginServerErrorsCache, (error) => error.value === attrs.login);
    if (cacheError) {
        return { login: [cacheError.error] };
    }
    return false;
};
const addLoginToServerErrorsCache = (fieldName, event, result) => {
    const cacheError = _.find(result.errors, (error) => _.contains(CACHE_LOGIN_ERRORS, error));
    if (cacheError) {
        const value = (event.currentTarget[fieldName] || {}).value;
        if (!_.find(loginServerErrorsCache, (error) => error.value === value)) {
            loginServerErrorsCache.push({ value, error: cacheError });
        }
    }
};
const bindLoginServerError = ($form, fieldName) => {
    if ($form && fieldName) {
        $form.on(`${fieldName}.error`, _.bind(addLoginToServerErrorsCache, $form, fieldName));
    }
};

/* name field validators */
const nameOptions = fieldOptions.name || {};
const nameRegexp = new RegExp(nameOptions.regexp);
const nameRequiredValidator = (attrs = {}, options = {}) => {
    if (options.prevalidate) {
        return false;
    }
    if (!attrs.name || attrs.name.length === 0) {
        return { __all__: [ERR_EMPTY_FIELD] };
    }
    return false;
};

const nameMinLengthValidator = (attrs = {}) => {
    if (attrs.name && attrs.name.length < nameOptions.min_length) {
        return { name: [ERR_MIN_LENGTH] };
    }
    return false;
};

const nameMaxLengthValidator = (attrs = {}) => {
    if (attrs.name && attrs.name.length > nameOptions.max_length) {
        return { name: [ERR_MAX_LENGTH] };
    }
    return false;
};

const namePatternValidator = (attrs = {}) => {
    if (attrs.name && !nameRegexp.test(attrs.name)) {
        return { name: [ERR_INVALID] };
    }
    return false;
};

const apiNicknameValidationUrl = _.extract(ACCOUNT_NAME_SETTING, '');
const cacheNameValidation = {};
const nameServerErrorsCache = {};
const promiseCache = {};
const nameServerPrevalidation = (attrs = {}, options = {}, errors = {}) => {
    if (errors.name) {
        return false;
    }
    if (!(cacheNameValidation[attrs.name] || {}).error) {
        if (nameServerErrorsCache[attrs.name]) {
            return { name: [nameServerErrorsCache[attrs.name]] };
        }
        if (options.presaveValidate) {
            return false;
        }
    }
    if (!errors.name && attrs.name) {
        let promise;

        if (promiseCache[attrs.name]) {
            promise = new Promise((resolve, reject) => {
                promiseCache[attrs.name].then(suggestion => {
                    resolve(suggestion);
                }, reject);
            });
        } else {
            promiseCache[attrs.name] = new Promise((resolve, reject) => {
                const data = { suggestions: 1 };
                let error;
                if (cacheNameValidation[attrs.name]) {
                    resolve(cacheNameValidation[attrs.name]);
                    delete promiseCache[attrs.name];
                    return;
                }
                const onSuggestions = (suggestions) => {
                    cacheNameValidation[attrs.name] = {
                        suggestions,
                        field: 'name',
                        error
                    };
                    resolve(cacheNameValidation[attrs.name]);
                    delete promiseCache[attrs.name];
                };
                const onNotSuggestions = (errorCode, spaId) => {
                    error = errorCode;
                    if (error) {
                        nameServerErrorsCache[attrs.name] = error;
                    }
                    setTimeout(() => {
                        if (!cacheNameValidation[attrs.name]) {
                            resolve({ error: errorCode, id: spaId });
                        }
                        delete promiseCache[attrs.name];
                    }, 50);
                };
                const onSuccess = () => {
                    cacheNameValidation[attrs.name] = {};
                    resolve({});
                    delete promiseCache[attrs.name];
                };
                if (attrs.login) {
                    data.email = attrs.login;
                }

                const nameValidationRequest = new ApiNicknameValidationRequest({
                    url: apiNicknameValidationUrl,
                    onBanned: _.bind(onNotSuggestions, this, ERR_NAME_BANNED),
                    onExists: _.bind(onNotSuggestions, this, ERR_NAME_EXIST),
                    onSuccess: _.bind(onSuccess, this),
                    onErrors: reject,
                    onHttpError: reject,
                    onSuggestions
                });
                nameValidationRequest.make(attrs.name, data);
            });
            promise = promiseCache[attrs.name];
        }
        return { name: [promise] };
    }
    return false;
};

/* password field validators */
const passwordOptions = fieldOptions.password || {};
const passwordRegexp = new RegExp(passwordOptions.regexp);
const passwordRequiredValidator = (attrs = {}, options = {}) => {
    if (options.prevalidate) {
        return false;
    }
    if (!attrs.password || attrs.password.length === 0) {
        return { __all__: [ERR_EMPTY_FIELD] };
    }
    return false;
};

const repasswordRequiredValidator = (attrs = {}, options = {}) => {
    if (options.prevalidate) {
        return false;
    }
    if (!attrs.re_password || attrs.re_password.length === 0) {
        return { __all__: [ERR_EMPTY_FIELD] };
    }
    return false;
};

const passwordMismatchValidator = (attrs = {}) => {
    if (!attrs.re_password) {
        return false;
    }
    if (attrs.password !== attrs.re_password) {
        return { re_password: [ERR_RE_PASSWORD_MISMATCH] };
    }
    return false;
};

const passwordMinLengthValidator = (attrs = {}) => {
    if (attrs.password && attrs.password.length < passwordOptions.min_length) {
        return { password: [ERR_MIN_LENGTH] };
    }
    return false;
};

const passwordMaxLengthValidator = (attrs = {}) => {
    if (attrs.password && attrs.password.length > passwordOptions.max_length) {
        return { password: [ERR_MAX_LENGTH] };
    }
    return false;
};

const passwordPatternValidator = (attrs = {}) => {
    if (attrs.password && !passwordRegexp.test(attrs.password)) {
        return { password: [ERR_INVALID] };
    }
    return false;
};

const passwordMatchesLoginValidator = (attrs = {}) => {
    if (attrs.login && attrs.password) {
        const loginParts = attrs.login.split('@');
        if (loginParts[0] === attrs.password) {
            return { password: [ERR_PASSWORD_MATCHES_LOGIN] };
        }
    }
    return false;
};

const passwordMatchesNameValidator = (attrs = {}, options = {}) => {
    if (options.prevalidate && !attrs.password) {
        return false;
    }
    if (attrs.name && attrs.name === attrs.password) {
        return { password: [ERR_PASSWORD_MATCHES_NAME] };
    }
    return false;
};

/* captcha field validators */
const captchaOptions = fieldOptions.captcha || {};
const captchaRegexp = new RegExp(captchaOptions.regexp);
const captchaRequiredValidator = (attrs = {}, options = {}) => {
    if (options.prevalidate) {
        return false;
    }
    if (!attrs.captcha && options.activeChallengeType === 'captcha') {
        return { captcha: [ERR_CAPTCHA_REQUIRED] };
    }
    return false;
};

const captchaPatternValidator = (attrs = {}) => {
    if (attrs.captcha && !captchaRegexp.test(attrs.captcha)) {
        return { captcha: [ERR_INVALID] };
    }
    return false;
};

const countryRequiredValidator = (attrs = {}, options = {}) => {
    if (options.prevalidate) {
        return false;
    }
    if (!attrs.country_code) {
        return {
            __all__: [ERR_EMPTY_FIELD],
            country_code: [ERR_REQUIRED_FIELD],
        };
    }
    return false;
};

/* bonus code field validators */
const bonusCodeMaxLengthValidator = (attrs = {}, options = {}) => {
    if (options.prevalidate && bonusCodeOptions.isPrepopulated()) {
        return false;
    }
    if (attrs.bonus_code && attrs.bonus_code.length > bonusCodeOptions.getMaxLength()) {
        return { bonus_code: [ERR_MAX_LENGTH] };
    }
    return false;
};

const bonusCodePatternValidator = (attrs = {}, options = {}) => {
    if (options.prevalidate && bonusCodeOptions.isPrepopulated()) {
        return false;
    }
    if (attrs.bonus_code && !bonusCodeOptions.getRegExp().test(attrs.bonus_code)) {
        return { bonus_code: [ERR_INVALID] };
    }
    return false;
};

const makeBonusCodeRequiredValidator = (state) => (attrs = {}, options = {}) => {
    if (options.prevalidate || !state.isRequired()) {
        return false;
    }
    if (!attrs.bonus_code) {
        return {
            __all__: [ERR_EMPTY_FIELD],
        };
    }
    return false;
};

const bonusCodeRequiredValidator = makeBonusCodeRequiredValidator(bonusCodeOptions);

const birthdateRequiredValidator = (attrs = {}, options = {}) => {
    if (!options.prevalidate && attrs.birthdate.length === 0) {
        return {
            __all__: [ERR_EMPTY_FIELD],
            birthdate: [ERR_REQUIRED_FIELD],
        };
    }

    return false;
};

// eslint-disable-next-line no-unused-vars
const birthdateValidator = (attrs = {}, options = {}, errors = {}) => {
    if (errors.birthdate || !attrs.birthdate) {
        return false;
    }

    if (attrs.birthdate.length > 0) {
        const selectedBirthdate = new Date(attrs.birthdate);
        const selectedYear = selectedBirthdate.getFullYear();
        const selectedMonth = selectedBirthdate.getMonth() + 1;
        const selectedDay = selectedBirthdate.getDate();
        const [inputYear, inputMonth, inputDay] = attrs.birthdate.split('-').map(Number);

        const isDateValid =
            selectedYear === inputYear &&
            selectedMonth === inputMonth &&
            selectedDay === inputDay;

        if (!isDateValid) {
            return {
                birthdate: [ERR_INVALID]
            };
        }
    }

    return false;
};

const parentalEmailRequiredValidator = (attrs = {}, options = {}, errors = {}) => {
    if (options.prevalidate || errors.birthdate) {
        return false;
    }
    const minorsService = MinorService.get();
    return minorsService.isMinor(attrs.birthdate);
};

const parentalEmailValidator = (attrs = {}, options = {}, errors = {}) => {
    const BLOCKING_ERRORS = {
        strict_login: !options.hasStrictRegistrationData,
    };

    const errorsList = _.keys(errors);
    const blockingErrorsList = Object.keys(BLOCKING_ERRORS).filter(key => BLOCKING_ERRORS[key]);
    const isBlockingErrors = _.intersection(blockingErrorsList, errorsList).length > 0;
    const isParentalEmailRequired = parentalEmailRequiredValidator(attrs, options, errors);

    if (isBlockingErrors) {
        return false;
    }

    if (!options.prevalidate) {
        if (!isParentalEmailRequired) {
            return false;
        }

        if (!attrs.parental_email) {
            return { parental_email: [ERR_EMPTY_FIELD] };
        }
    }

    if (_.isString(attrs.parental_email) && attrs.parental_email.length > 0) {
        const newOptions = { redefinedFieldName: 'parental_email' };
        const updatedOptions = Object.assign({}, options, newOptions);
        const emailPatternError = emailPatternValidator(attrs, updatedOptions, errors);

        if (emailPatternError) {
            return emailPatternError;
        }
    }

    return false;
};

/* eula field validators */
const eulaRequiredValidator = (attrs = {}, options = {}) => {
    if ((!options.prevalidate || options.presaveValidate) && !attrs.eula) {
        return { eula: [ERR_EULA_REQUIRED] };
    }
    return false;
};

export {
    ERR_SERVER,
    ERR_UNKNOWN,
    ERR_DISABLED_DOMAIN,
    ERR_EMPTY_FIELD,
    ERR_MIN_LENGTH,
    ERR_MAX_LENGTH,
    ERR_INVALID,
    ERR_EULA_REQUIRED,
    ERR_CAPTCHA_REQUIRED,
    ERR_PASSWORD_MATCHES_LOGIN,
    ERR_PASSWORD_MATCHES_NAME,
    ERR_RE_PASSWORD_MISMATCH,
    ERR_NAME_BANNED,
    ERR_NAME_EXIST,
    ERR_COUNTRY_CONFLICT,
    ACCOUNT_NAME_SETTING,

    loginRequiredValidator,
    loginMinLengthValidator,
    loginMaxLengthValidator,
    emailPatternValidator,
    loginEmailDomainCheckValidator,
    loginServerCacheValidator,
    loginDisabledDomainsValidator,
    bindLoginServerError,

    nameRequiredValidator,
    nameMinLengthValidator,
    nameMaxLengthValidator,
    namePatternValidator,
    nameServerPrevalidation,

    passwordRequiredValidator,
    passwordMismatchValidator,
    passwordMinLengthValidator,
    passwordMaxLengthValidator,
    passwordPatternValidator,
    passwordMatchesLoginValidator,
    passwordMatchesNameValidator,
    repasswordRequiredValidator,

    captchaPatternValidator,
    captchaRequiredValidator,

    countryRequiredValidator,

    bonusCodeMaxLengthValidator,
    bonusCodePatternValidator,
    makeBonusCodeRequiredValidator,
    bonusCodeRequiredValidator,

    birthdateRequiredValidator,
    birthdateValidator,
    parentalEmailRequiredValidator,
    parentalEmailValidator,

    eulaRequiredValidator
};
