import MinorsService from '@/lib/services/MinorsService';
import { birthdateRequiredValidator, birthdateValidator, parentalEmailValidator } from '@/lib/validators';
import { mergeValidationErrors } from '@/utils/helpers';

import BasicFormModel from './basic_form_model';

const RegistrationFormModel = BasicFormModel.extend({
    minorsService: MinorsService.get(),

    validateMinorFields(attrs, options) {
        const errors = [birthdateRequiredValidator, birthdateValidator, parentalEmailValidator]
            .map((validator) => validator(attrs, options));

        return mergeValidationErrors(...errors);
    },

    validate(attrs = {}, options = {}) {
        const basicErrors = BasicFormModel.prototype.validate.call(this, attrs, options);

        const minorRestrictionsEnabled = this.minorsService.isRestrictionEnabled(
            this.get('country_code')
        );

        if (!minorRestrictionsEnabled) {
            return basicErrors;
        }
        const errors = mergeValidationErrors(basicErrors, this.validateMinorFields(attrs, options));
        return Object.keys(errors).length > 0 ? errors : false;
    },

    save(...args) {
        const minorRestrictionsEnabled = this.minorsService.isRestrictionEnabled(
            this.get('country_code')
        );
        if (!minorRestrictionsEnabled) {
            this.unset('birthdate');
            this.unset('parental_email');
        }
        return BasicFormModel.prototype.save.call(this, ...args);
    }
});

export default RegistrationFormModel;
