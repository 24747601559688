const regOptionsPath = 'Settings.Registration.registrationOptions';
const fieldsOptionsPath = 'Settings.Registration.fields';

const EMAIL_SETTING = `${fieldsOptionsPath}.login.value`;
const EXTERNAL_PROVIDER_SETTING = 'Settings.Registration.externalProvider';
const EXTERNAL_CODE_SETTING = `${EXTERNAL_PROVIDER_SETTING}.code`;
const NAME_FLOW_SETTING = `${fieldsOptionsPath}.name.flow`;
const PASSWORD_FLOW_SETTING = `${fieldsOptionsPath}.password.flow`;
const STRICT_HINT_ENABLED_SETTING = `${regOptionsPath}.suggestionsHardPopup`;
const EXTERNAL_STARTED_EVENT = 'wgnet.registration.external-service.started';
const EXTERNAL_SIGNIN_STARTED_EVENT = 'wgnet.registration-signin.external-service.started';
const EXTERNAL_CANCEL_EVENT = 'wgnet.registration.external-service.canceled';
const EXTERNAL_SWITCH_FORM_EVENT = 'wgnet.registration.external-service.switch-to-reg';
const REDIRECT_ERRORS = ['conflict_uid_external', 'conflict_login_external'];

const TABS_STATE = {
    REG: 0,
    LOGIN: 1
};

const TABS_EVENTS = [
    'regToLogin',
    'loginToReg'
];

const GA_LENGTH = {
    LONG: 'long',
    SHORT: 'short'
};

const KEYCODES = {
    TAB: 9,
    ENTER: 13,
    SHIFT: 16
};

const NAME_SUGGESTION_ERRORS = [
    'already_exist',
    'in_stoplist',
    'banned',
    'conflict_nickname'
];

const ERROR_BLOCK_EVENTS = {
    show: 'registration_form.ErrorBlock.show',
    hide: 'registration_form.ErrorBlock.hide',
};

const BIRTHDATE_EVENTS = {
    clear: 'registration_form.birthdate.clear',
    render: 'registration_form.birthdate.render',
    save: 'registration_form.birthdate.save',
    set_external: 'registration_form.birthdate.set_external',
};

const BIRTHDATE_FIELD_EVENTS = {
    hide: 'registration_view.birthdate.hide',
    show: 'registration_view.birthdate.show',
};

const PARENTAL_EMAIL_FIELD_EVENTS = {
    clear: 'registration_view.parental_email_field.clear',
    hide: 'registration_view.parental_email_field.hide',
    show: 'registration_view.parental_email_field.show',
};

const REGISTRATION_FORM_VIEWS = {
    main: Symbol('main'),
    parental_email: Symbol('parental_email'),
};

const REGISTRATION_VIEW_EVENTS = {
    EXTERNAL_VIEW: {
        hide: 'registration_view.external_view.hide',
        show: 'registration_view.external_view.show',
    }
};

export {
    BIRTHDATE_EVENTS,
    BIRTHDATE_FIELD_EVENTS,
    EMAIL_SETTING,
    EXTERNAL_CANCEL_EVENT,
    EXTERNAL_CODE_SETTING,
    EXTERNAL_PROVIDER_SETTING,
    EXTERNAL_SIGNIN_STARTED_EVENT,
    EXTERNAL_STARTED_EVENT,
    EXTERNAL_SWITCH_FORM_EVENT,
    GA_LENGTH,
    KEYCODES,
    NAME_FLOW_SETTING,
    NAME_SUGGESTION_ERRORS,
    PARENTAL_EMAIL_FIELD_EVENTS,
    PASSWORD_FLOW_SETTING,
    REDIRECT_ERRORS,
    ERROR_BLOCK_EVENTS,
    REGISTRATION_FORM_VIEWS,
    REGISTRATION_VIEW_EVENTS,
    STRICT_HINT_ENABLED_SETTING,
    TABS_EVENTS,
    TABS_STATE,
};
