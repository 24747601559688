export default {};
export function cached(fn, ctx) {
    let cachedResult;
    let currentFn;
    const nextFn = () => cachedResult;
    const firstFn = (...args) => {
        cachedResult = fn.call(ctx, ...args);
        currentFn = nextFn;
        return cachedResult;
    };
    currentFn = firstFn;
    const resultFn = (...args) => currentFn(...args);
    resultFn.clear = () => {
        currentFn = firstFn;
    };
    return resultFn;
}
