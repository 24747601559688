export default function isBirthdateValid(val) {
    if (!val || val.split('-').length !== 3) {
        return false;
    }

    const birthdate = new Date(val);
    const date = birthdate.getDate();
    const month = birthdate.getMonth() + 1;
    const year = birthdate.getFullYear();

    if (!(date && month && year)) {
        return false;
    }

    const [y, m, d] = val.split('-').map(Number);

    if (date !== d || month !== m || year !== y) {
        return false;
    }

    const timestamp = birthdate.getTime();

    if (timestamp > Date.now()) {
        return false;
    }

    return true;
}
