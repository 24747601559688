import BaseErrorField from 'registration/js/base/fields/error';

import template from 'registration/templates/simple/fields/error.html';

const ErrorField = BaseErrorField.extend({
    template,
    errorClass: 'input-light__error',
    hideError() {
        this.$el.trigger('field.description.show');
        BaseErrorField.prototype.hideError.call(this);
    },
    renderErrors(...args) {
        this.$el.trigger('field.description.hide');
        BaseErrorField.prototype.renderErrors.apply(this, args);
    },
    showHint() {},
    hideHint() {}
});

export default ErrorField;
