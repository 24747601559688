import {
    _
} from 'wgnet-awesomejs';

import BaseComponent from 'wgnet-awesomejs/base/component';

const BaseDescriptionField = BaseComponent.extend({
    name: 'description-field',
    template() {
        return '';
    },
    afterInitialize() {
        this.$fieldset = this.$el.parents('.js-fieldset');
        this.$hint = this.$('.js-form-field-hint-description');
        this.$fieldset.on('field.description.hide', _.bind(this.hideDescription, this));
        this.$fieldset.on('field.description.show', _.bind(this.showDescription, this));
    },
    showDescription() {
        this.$hint.removeClass('hidden');
    },
    hideDescription() {
        this.$hint.addClass('hidden');
    }
});

export default BaseDescriptionField;
