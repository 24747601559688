var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as types from '@/types';
import { cached } from '@/utils/decorators';
const DEFAULT_ADULT_AGE = 18;
let minorsService;
export default class MinorService {
    settings;
    static get() {
        if (!minorsService) {
            let settings = {
                adult_age: DEFAULT_ADULT_AGE,
                restrictions: [],
                country_code: null,
            };
            if (types.isRegistrationMinorsSettings(window?.Settings?.Registration?.minors)) {
                settings = {
                    ...settings,
                    ...window.Settings.Registration.minors,
                };
            }
            minorsService = new MinorService(settings);
        }
        return minorsService;
    }
    constructor(settings) {
        this.settings = settings;
    }
    isRestrictionEnabled(countryCode) {
        const _countryCode = countryCode || this.settings.country_code || '';
        return this.settings.restrictions.some(({ country_list: countryList, policy }) => {
            switch (policy) {
                case 'exclude': {
                    return !countryList.includes(_countryCode);
                }
                case 'include':
                default: {
                    return countryList.includes(_countryCode);
                }
            }
        });
    }
    isMinor(birthdate) {
        const birthdateDate = new Date(birthdate);
        if (birthdateDate.toString() === 'Invalid Date') {
            return false;
        }
        const now = new Date();
        let age = now.getFullYear() - birthdateDate.getFullYear();
        const m = now.getMonth() - now.getMonth();
        if (m < 0 || (m === 0 && now.getDate() < birthdateDate.getDate())) {
            age -= 1;
        }
        return age < this.settings.adult_age;
    }
}
__decorate([
    cached((countryCode) => `${countryCode || ''}`)
], MinorService.prototype, "isRestrictionEnabled", null);
