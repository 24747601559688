export const mergeValidationErrors = (...args) => {
    return args.reduce((merged, error) => {
        if (!error)
            return merged;
        return Object.entries(error).reduce((mergedErrors, [key, value]) => {
            switch (key) {
                case 'strict_login': {
                    return {
                        ...mergedErrors,
                        [key]: value,
                    };
                }
                default: {
                    if (Array.isArray(value)) {
                        let prevErrors = (mergedErrors?.[key] || []);
                        return {
                            ...mergedErrors,
                            [key]: Array.from(new Set([...prevErrors, ...value])),
                        };
                    }
                    return mergedErrors;
                }
            }
        }, merged);
    }, {});
};
