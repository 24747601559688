import { $, _ } from 'wgnet-awesomejs';
import mobile from 'wgnet-awesomejs/lib/mobile';

export default function initTooltip(container) {
    const el = $(container);
    if (el.length === 0) {
        return null;
    }
    if (!mobile().useMobile) {
        return null;
    }
    el.on('click', '.js-tooltip', (event) => {
        const tooltip = $(event.currentTarget);
        const wrapper = $('.js-tooltip-wrapper', tooltip);
        const openClass = tooltip.data('open-class');
        const isRtlLang = _.extract('Settings.cssDirection') === 'rtl';
        let right;
        let left;
        if (isRtlLang) {
            right = (tooltip.offset().left + (2 * tooltip.width())) - window.innerWidth;
        } else {
            left = -tooltip.offset().left;
        }
        wrapper.css({ left, right });
        tooltip.addClass(openClass);
        return false;
    });
    el.on('click', '.js-tooltip-close', (event) => {
        const tooltip = $(event.currentTarget).parents('.js-tooltip')[0];
        const openClass = tooltip.getAttribute('data-open-class');
        tooltip.classList.remove(openClass);
        event.stopPropagation();
        return false;
    });
    return el;
}
