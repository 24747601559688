// https://jira.wargaming.net/browse/WGNR-5096

import { _ } from 'wgnet-awesomejs';

export const DEFAULT_PARAMS_DELIMITER = ' | ';
export const DEFAULT_EVENTS_NAMESPACE = 'autoEvent_wgnr';

export const GTM_EVENTS = {
    FORM_STATE_CHANGE: {
        loginToReg: 'FORM_STATE_REGISTRATION',
        regToLogin: 'FORM_STATE_LOGIN'
    },
    INVITE_CODE_INTERACTION: 'INVITE_CODE_INTERACTION',
    LOGIN_FORM_CHECKBOX_CLICK: 'LOGIN_FORM_CHECKBOX_CLICK',
    PASSWORD_FIELD_STATE_CHANGE: {
        hide: 'PASSWORD_FIELD_STATE_HIDE',
        show: 'PASSWORD_FIELD_STATE_SHOW'
    },
    RECOVERY_LINK_CLICK: 'RECOVERY_LINK_CLICK',
    REG_FORM_CHECKBOX_CLICK: 'REG_FORM_CHECKBOX_CLICK',
    REG_FORM_SUBMIT: 'REG_FORM_SUBMIT',
    REG_FORM_VALIDATION: {
        error: 'REG_FORM_VALIDATION_ERROR',
        success: 'REG_FORM_VALIDATION_SUCCESS'
    },
    SN_CLICK: 'SN_CLICK'
};

export const EVENTS_CATEGORIES = {
    REG_FORM_ELEMENTS: 'REG_FORM_ELEMENTS',
    LOGIN_FORM_ELEMENTS: 'LOGIN_FORM_ELEMENTS',
    CTA_ELEMENTS: 'CTA',
};

export const EVENTS_CATEGORIES_LABELS = {
    [EVENTS_CATEGORIES.REG_FORM_ELEMENTS]: 'wgnr | reg form elements',
    [EVENTS_CATEGORIES.LOGIN_FORM_ELEMENTS]: 'wgnr | login form elements',
    [EVENTS_CATEGORIES.CTA_ELEMENTS]: 'wgnr | cta elements',
};

export const EVENTS = {
    [GTM_EVENTS.FORM_STATE_CHANGE.loginToReg]: {
        action: 'reg form create account link | click',
        category: EVENTS_CATEGORIES.REG_FORM_ELEMENTS
    },
    [GTM_EVENTS.FORM_STATE_CHANGE.regToLogin]: {
        action: 'reg form already have account link | click',
        category: EVENTS_CATEGORIES.REG_FORM_ELEMENTS
    },
    [GTM_EVENTS.INVITE_CODE_INTERACTION]: {
        action: 'reg form invite code | click',
        category: EVENTS_CATEGORIES.REG_FORM_ELEMENTS
    },
    [GTM_EVENTS.LOGIN_FORM_CHECKBOX_CLICK]: {
        action: 'login form checkbox | click',
        category: EVENTS_CATEGORIES.LOGIN_FORM_ELEMENTS
    },
    [GTM_EVENTS.PASSWORD_FIELD_STATE_CHANGE.hide]: {
        action: 'login form password | hide',
        category: EVENTS_CATEGORIES.LOGIN_FORM_ELEMENTS
    },
    [GTM_EVENTS.PASSWORD_FIELD_STATE_CHANGE.show]: {
        action: 'login form password | show',
        category: EVENTS_CATEGORIES.LOGIN_FORM_ELEMENTS
    },
    [GTM_EVENTS.RECOVERY_LINK_CLICK]: {
        action: 'login form forgot password | click',
        category: EVENTS_CATEGORIES.LOGIN_FORM_ELEMENTS
    },
    [GTM_EVENTS.REG_FORM_CHECKBOX_CLICK]: {
        action: 'reg form checkbox | click',
        category: EVENTS_CATEGORIES.REG_FORM_ELEMENTS
    },
    [GTM_EVENTS.REG_FORM_SUBMIT]: {
        action: 'reg form submit | click',
        category: EVENTS_CATEGORIES.REG_FORM_ELEMENTS
    },
    [GTM_EVENTS.REG_FORM_VALIDATION.error]: {
        action: 'reg form error | error',
        category: EVENTS_CATEGORIES.REG_FORM_ELEMENTS
    },
    [GTM_EVENTS.REG_FORM_VALIDATION.success]: {
        action: 'cta success form submit | submit finish',
        category: EVENTS_CATEGORIES.CTA_ELEMENTS
    },
    [GTM_EVENTS.SN_CLICK]: {
        action: {
            default: 'reg',
            login: 'social login button | click',
            reg: 'social registration button | click'
        },
        category: EVENTS_CATEGORIES.REG_FORM_ELEMENTS,
        labels: {
            login: 'login form',
            reg: 'registration form'
        }
    },
};

const DEFAULT_EVENT_PARAMS = {
    eventCallback: () => {},
    eventTimeout: 2000,
};

export default function pushGaEvent(eventParams = {}) {
    const params = Object.assign({}, DEFAULT_EVENT_PARAMS, eventParams);
    const { action, category, event, label } = params;
    const pushedEvent = EVENTS[event];
    const isEventValid = event in EVENTS;

    if (!window.dataLayer || !isEventValid) {
        return Promise.resolve();
    }

    const { category: defaultCategory, labels } = pushedEvent || {};

    let eventCategory = EVENTS_CATEGORIES_LABELS[defaultCategory];
    let eventAction = pushedEvent.action;
    let labelsList = Array.isArray(label) ? label : [label];

    if (category && category in EVENTS_CATEGORIES) {
        eventCategory = EVENTS_CATEGORIES_LABELS[category];
    }

    if (!_.isString(eventAction)) {
        const isActionKeyValid = action && eventAction[action];
        const actionKey = isActionKeyValid ? action : eventAction.default;

        eventAction = eventAction[actionKey];
    }

    if (labels) {
        labelsList = labelsList.map((key) => (key in labels ? labels[key] : key));
    }

    const eventLabel = labelsList.join(DEFAULT_PARAMS_DELIMITER);

    return new Promise(resolve => {
        const readyEvent = {
            event: DEFAULT_EVENTS_NAMESPACE,
            eventAction,
            eventCategory
        };

        if (eventLabel) {
            readyEvent.eventLabel = eventLabel;
        }

        window.dataLayer.push(readyEvent);
        resolve(null);
    });
}
