import Application from 'wgnet-awesomejs';
import initInputNumberCheck from 'wgnet-awesomejs/lib/number_input_check';
import URI from 'urijs';

import themeSetting from 'registration/js/lib/theme_settings';

import _ from 'underscore';
import BaseInput from './input';

const BaseCaptchaField = BaseInput.extend({
    name: 'captcha-field',
    fieldName: 'captcha',
    beforeInitialize(options = {}) {
        options['pow-allowed'] = options['pow-allowed'] === 'true' || false;
    },
    updateCaptcha() {
        this.$form.trigger('reload.challenge.captcha');
        return false;
    },
    onInput: true,
    onKeyUp: true,
    onKeyDown(event = {}) {
        const key = event.which || event.keyCode;
        if (key === 13) {
            this.$el.trigger('submit');
            return false;
        }
        return true;
    },
    afterInitialize(...args) {
        BaseInput.prototype.afterInitialize.apply(this, args);
        initInputNumberCheck(this.$('input'), this);
        this.$form.on('captcha.show', _.bind(this.show, this));
        this.$form.on('captcha.hide', _.bind(this.hide, this));
        this.$image = this.$('.js-captcha-image');
        this.delegateEvents({
            'click .js-captcha-reload': 'updateCaptcha'
        });
    },
    getImageSrc({ url }) {
        const theme = themeSetting.captcha || '';
        return URI(url).addSearch('theme', theme).toString();
    },
    show(event, data) {
        this.$image.attr('src', this.getImageSrc(data));
        this.$input.val('');
        this.$el.removeClass('hidden');
        Application.trigger('closesuggestdialog');
    },
    hide() {
        this.$fieldset.addClass('hidden');
        this.$image.removeAttr('src');
    }
});
export default BaseCaptchaField;
