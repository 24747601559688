import {
    _, $
} from 'wgnet-awesomejs';
import BaseExternalView from 'registration/js/base/external_view';

import template from 'registration/templates/simple/external.html';
import {
    REDIRECT_ERRORS
} from 'registration/js/base/constants';

const ExternalServiceView = BaseExternalView.extend({
    template,
    externalStart(provider) {
        const providerName = BaseExternalView.prototype.externalStart.call(this, provider);
        if (providerName) {
            this.initBaseThemeSpin();
        }
        return providerName;
    },
    getDataComplete(result = {}) {
        if (result.status === 'processing') {
            this.onFormError(null, 'no_connection');
            return;
        } else if (result.status === 'error') {
            return;
        }
        const { firstName, lastName, nickname } = result.data || {};
        const context = {
            externalUser: result.data || {},
            complete: true,
            showExtraMessage: true,
            external: _.extract('Settings.Registration.externalProvider.name'),
            provider: _.extract('Settings.Registration.externalProvider.code', this.currentProvider),
            branding: _.extract('Settings.Registration.branding'),
        };
        if (!context.externalUser.fullName) {
            if (firstName || lastName) {
                const separate = firstName && lastName ? ' ' : '';
                context.externalUser.fullName = `${firstName || ''}${separate}${lastName || ''}`;
            } else if (nickname) {
                context.externalUser.fullName = nickname;
            }
        }
        this.render(null, context);
        this.parent.showForm(result.data);
        $('.js-simple-registration-login-link').removeClass('hidden');
    },
    onFormError(error, errorsContext, provider) {
        if (!error || error.length === 0) {
            return;
        }
        if (REDIRECT_ERRORS.includes(error) && !this.verificationProviders.includes(provider)) {
            const redirectUrl = this.settings().authenticationUrl;
            this.redirectTo(redirectUrl);
            return;
        }
        const context = {
            complete: true,
            hasError: true,
            showExtraMessage: false,
            external: _.extract('Settings.Registration.externalProvider.name'),
            provider: _.extract('Settings.Registration.externalProvider.code', this.currentProvider || provider),
            branding: _.extract('Settings.Registration.branding'),
        };
        this.render(null, context);
        this.clearExternalData();
        this.parent.triggerCommonError(this.tab, 'error', {
            errors: [error],
            errorsContext,
            errorPrefix: this.errorPrefix,
            errorSuffix: this.currentProvider || provider
        });
    },
    errorPrefix: 'simple',
    render(type, context = {}) {
        const rendered = this.template(context);
        this.$el.html(rendered);
        return rendered;
    }
});

export default ExternalServiceView;
