import Application, {
    $, _
} from 'wgnet-awesomejs';
import BaseComponent from 'wgnet-awesomejs/base/component';

import createStorageManager from 'registration/js/lib/storage_manager';
import isBirthdateValid from 'registration/js/lib/validators/birthdate';
import {
    generateDaysList,
    generateMonthsList,
    generateYearsList,
} from 'registration/js/lib/generators';
import { BIRTHDATE_EVENTS, BIRTHDATE_FIELD_EVENTS } from 'registration/js/base/constants';

import BirthdateDropdown from './birthdate_dropdown';

const birthdateStorageManager = createStorageManager('wgnr_birthdate');

const USER_MIN_BIRTHDATE_YEAR = 1900;
const INPUT_GAME_CLASSES = {
    error: 'input-game__error',
    hover: 'input-game__hover',
};
const FIELD_NAMES = {
    day: 'birthdate_day',
    month: 'birthdate_month',
    year: 'birthdate_year',
};
const BIRTHDATE_DROPDOWNS = [
    {
        key: 'year',
        optionsList: generateYearsList(USER_MIN_BIRTHDATE_YEAR),
    },
    {
        key: 'month',
        optionsList: generateMonthsList(),
    },
    {
        key: 'day',
        optionsList: generateDaysList(),
    },
];

const BirthdateField = BaseComponent.extend({
    name: 'birthdate-field',
    template: () => '<span>The template must be defined for the Birthdate component</span>',

    BirthdateDropdown,

    savedBirthdate: null,
    dropdowns: {},
    disabled: false,

    beforeInitialize(options = {}) {
        options.visible = options?.visible === 'true';
        if (!options.externalBirthdateData) {
            this.getBirthdateFromStorage();
        }
    },
    afterInitialize(options = {}) {
        const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

        this.$form = this.$el.parents('form');
        this.$inputGame = $('.input-game', this.$el);
        this.$inputBirthdate = $('input[name="birthdate"]', this.$el);
        this.$fieldset = this.$el.parents('.js-fieldset');

        $('input[name="timezone"]', this.$el).val(timeZone);

        this.initEvents();
        this.initDropdownElements(BIRTHDATE_DROPDOWNS);
        this.getBirthdateFromExternal(options.externalBirthdateData);
        this.setSavedBirthdate();
    },
    render(...args) {
        const $rendered = $(BaseComponent.prototype.render.call(this, args));

        this.$el.replaceWith($rendered);
        this.$el = $rendered;

        return $rendered;
    },
    getContext(...args) {
        const result = BaseComponent.prototype.getContext.apply(this, args);
        const { disabled } = this;

        result.push({
            disabled,
        });

        return result;
    },
    initDropdownElements(dropdowns) {
        const elementIdPrefix = '#birthdate-dropdown';
        const messagePrefix = 'Settings.messages.registration-birthdate_dropdown-title';

        dropdowns.forEach(({ key, optionsList }) => {
            this.dropdowns[key] = new this.BirthdateDropdown({
                disabled: this.disabled,
                el: `${elementIdPrefix}-${key}`,
                key,
                name: FIELD_NAMES[key],
                optionsList,
                parent: this,
                placeholder: _.extract(`${messagePrefix}-${key}`),
            });
        });
    },
    initEvents() {
        $(document).on('click', _.bind(this.closeDropdowns, this));

        Application.on(BIRTHDATE_EVENTS.set_external, _.bind(this.getBirthdateFromExternal, this));

        this.$form.on('birthdate.error', _.bind(this.setErrorState, this));
        this.$form.on('birthdate.valid', _.bind(this.setValidState, this));
        this.$form.on(BIRTHDATE_EVENTS.clear, _.bind(this.removeBirthdateFromStorage, this));
        this.$form.on(BIRTHDATE_EVENTS.render, _.bind(this.updateView, this));
        this.$form.on(BIRTHDATE_EVENTS.save, _.bind(this.saveBirthdateToStorage, this));

        this.$form.on(BIRTHDATE_FIELD_EVENTS.show, () => {
            this.$el.show();
        });
        this.$form.on(BIRTHDATE_FIELD_EVENTS.hide, () => {
            this.$el.hide();
        });

        this.delegateEvents({
            mouseover: () => this.$inputGame.addClass(INPUT_GAME_CLASSES.hover),
            mouseleave: () => this.$inputGame.removeClass(INPUT_GAME_CLASSES.hover),
        });
    },
    closeDropdowns() {
        Object.values(this.dropdowns).forEach(dropdown => dropdown.close());
    },
    onSelect(value, { silent = false } = {}) {
        this.closeDropdowns();
        this.fillBirthdateInput();
        this.setValidState();
        if (!silent) {
            this.$form.trigger('form.prevalidate');
        }
    },
    getBirthdateFromExternal(birthdate) {
        if (birthdate) {
            this.saveBirthdateState({
                birthdate,
                disable: false,
            });
            this.setSavedBirthdate();
        }
    },
    getBirthdateFromStorage() {
        const birthdate = birthdateStorageManager.get();

        this.saveBirthdateState({
            birthdate,
            disable: true,
        });
    },
    setSavedBirthdate() {
        if (this.savedBirthdate) {
            Object.values(this.dropdowns).forEach(dropdown => {
                const { key } = dropdown;
                const value = this.savedBirthdate[key];

                dropdown.setComponentData({ key, value }, { silent: true });
            });
        }
    },
    saveBirthdateState({ birthdate, disable = false } = {}) {
        if (isBirthdateValid(birthdate)) {
            const [year, month, day] = birthdate.split('-');

            this.disabled = disable;
            this.savedBirthdate = {
                year,
                month,
                day,
            };
        }
    },
    getUserBirthdate() {
        const day = this.dropdowns.day.value;
        const month = this.dropdowns.month.value;
        const year = this.dropdowns.year.value;

        return {
            day,
            month,
            year,
        };
    },
    removeBirthdateFromStorage() {
        birthdateStorageManager.clear();
    },
    saveBirthdateToStorage() {
        const userBirthdate = this.getUserBirthdate();

        this.disabled = true;
        this.savedBirthdate = userBirthdate;

        birthdateStorageManager.set(this.$inputBirthdate.val());
    },
    fillBirthdateInput() {
        const { year, month, day } = this.getUserBirthdate();

        let inputBirthdateValue = '';

        if (year && month && day) {
            inputBirthdateValue = `${year}-${month}-${day}`;
        }

        this.$inputBirthdate.val(inputBirthdateValue);
    },
    setErrorState() {
        this.$inputGame.addClass(INPUT_GAME_CLASSES.error);
        this.$fieldset.data('error-state', true);
    },
    setValidState() {
        this.$inputGame.removeClass(INPUT_GAME_CLASSES.error);
        this.$fieldset.data('error-state', false);
    },
    updateView() {
        this.render();
        this.afterInitialize();
    }
});

export default BirthdateField;
