const privateState = new WeakMap();

class CountryCodeOptions {
    constructor({
        allowed_list: _countries,
        user_country_code: _userCountryCode,
        enabled,
    } = {}) {
        const countries = _countries || {};
        const userCountryCode = _userCountryCode || null;
        privateState.set(this, {
            enabled: Boolean(enabled),
            userCountryCode,
            countries,
            countriesOptions: Object.entries(countries)
                .map(([value, label]) => ({ icon: value.toLowerCase(), value, label })),
        });
    }

    get enabled() {
        return privateState.get(this).enabled;
    }

    get visible() {
        return !this.userCountryAllowed;
    }

    get userCountryAllowed() {
        if (!this.enabled) {
            return true;
        }
        const state = privateState.get(this);
        return state.userCountryCode in state.countries;
    }

    get userCountryCode() {
        return privateState.get(this).userCountryCode;
    }

    get options() {
        return privateState.get(this).countriesOptions;
    }

    getCountryName(countryCode) {
        return privateState.get(this).countries[countryCode];
    }

    isValidCountryCode(countryCode) {
        return Boolean(privateState.get(this).countries[countryCode]);
    }

}

export default CountryCodeOptions;
