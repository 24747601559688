import { _ } from 'wgnet-awesomejs';

/*
    custom goal keys:
    registration success: "^.*registration success.*$"
*/
const abTestWrapper = function abTestWrapper(fn, goalKey) {
    if (!_.isFunction(fn)) {
        return fn;
    }

    const triggerFn = function triggerFn(...args) {
        /* eslint no-underscore-dangle: ["off"] */
        const hasRunningExperiments = _.isArray(window._vwo_exp_ids)
            && window._vwo_exp_ids.length > 0
            && _.isFunction(window._vis_opt_register_conversion);

        if (hasRunningExperiments) {
            for (let i = 0; i < window._vwo_exp_ids.length; i += 1) {
                const expId = window._vwo_exp_ids[i];
                const exp = window._vwo_exp[expId] || {};
                const goals = exp.goals || {};
                const goalIds = Object.keys(goals);
                for (let j = 0; j < goalIds.length; j += 1) {
                    const goalId = goalIds[j];
                    const goal = goals[goalId];
                    if (goal.type === 'CUSTOM_GOAL' && goal.pUrl === goalKey) {
                        window._vis_opt_register_conversion(goalId, expId);
                    }
                }
            }
            setTimeout(() => fn.call(this, ...args), 500);
        } else {
            fn.call(this, ...args);
        }
    };
    return triggerFn;
};

export default abTestWrapper;
