import $ from 'jquery';

$('body').on('mouseover', '.js-checkbox-label', event => {
    const { target, currentTarget } = event;
    if (target.nodeName !== 'A') {
        const label = $(currentTarget);
        label.addClass(label.attr('data-hover-class'));
    }
});

$('body').on('mouseout', '.js-checkbox-label', event => {
    const { currentTarget } = event;
    const label = $(currentTarget);
    label.removeClass(label.attr('data-hover-class'));
});
