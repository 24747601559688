import Spinner from 'registration/js/lib/spinner';
import BaseComponent from 'wgnet-awesomejs/base/component';
import {
    _, $
} from 'wgnet-awesomejs';

const BaseSubmitButton = BaseComponent.extend({
    name: 'submit-button',
    spinnerOptions: {
        modifiers: ['big-button']
    },
    template() {
        return '<input type="submit" class="js-registration-form-spinner" value="Send"/>';
    },
    afterInitialize() {
        this.$form = this.$el.parents('form');
        this.$form.on('spinner.start.base', _.bind(this.waitStart, this));
        this.$form.on('spinner.stop.base', _.bind(this.waitStop, this));
        this.$throbbing = this.$('.js-registration-form-spinner');
        if (this.$throbbing.length === 0) {
            this.$throbbing = this.$el;
        }
    },
    spinner() {
        this.spinnerInstance = this.spinnerInstance || new Spinner(this.spinnerOptions);
        return this.spinnerInstance;
    },
    waitStart() {
        this.spinner().spin(this.$throbbing[0]);
    },
    waitStop() {
        this.spinner().stop();
    },
    render(...args) {
        const $rendered = $(BaseComponent.prototype.render.call(this, args));
        this.$el.replaceWith($rendered);
        this.$el = $rendered;
        this.el = $rendered[0];
        return $rendered;
    }
});

export default BaseSubmitButton;
