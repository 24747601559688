import { $ } from 'wgnet-awesomejs';

import BaseComponent from 'wgnet-awesomejs/base/component';

import mobile from 'wgnet-awesomejs/lib/mobile';

const ACTIVE_LIST_ITEM_CLASS = 'dropdown-menu_item__active';

const BirthdateDropdown = BaseComponent.extend({
    template: () => '<span>The template must be defined for the BirthdateDropdown component</span>',

    value: null,
    dropdownSelector: 'custom-selector',

    beforeInitialize(options) {
        const {
            disabled,
            key,
            name,
            optionsList,
            parent,
            placeholder,
        } = options;

        this.disabled = disabled;
        this.key = key;
        this.name = name;
        this.optionsList = optionsList;
        this.parent = parent;
        this.placeholder = placeholder;
    },
    afterInitialize() {
        this.$select = $('select', this.$el);
        this.$list = $('ul', this.$el);

        this.delegateEvents({
            [`click .${this.dropdownSelector}_select`]: 'open',
            'click li.dropdown-menu_item': 'onSelect',
            'change select': 'onChange',
        });
    },
    getContext(context = {}) {
        const result = BaseComponent.prototype.getContext.call(this, context);

        const { name, placeholder } = this;

        result.push({
            name,
            id: name,
            placeholder,
            isMobile: mobile().useMobile,
        });

        return result;
    },
    getDropdownClass(classModifier) {
        if (!classModifier) {
            return '';
        }

        let modifier = classModifier;

        if (this.classModifiersMap) {
            modifier = this.classModifiersMap[classModifier] || classModifier;
        }

        return `${this.dropdownSelector}__${modifier}`;
    },
    open(e) {
        e.stopPropagation();
        e.preventDefault();

        const isOpened = this.$el.hasClass(this.getDropdownClass('open'));

        this.parent.closeDropdowns();

        if (!isOpened) {
            this.$el.addClass(this.getDropdownClass('open'));
            this.scrollToSelectedOption();
        }
    },
    close() {
        this.$el.removeClass(this.getDropdownClass('open'));
    },
    onChange(e) {
        const el = e.currentTarget;

        const value = $(el).val();
        const text = $('option:selected', $(el)).text().trim();

        this.setComponentData({ text, value });
    },
    onSelect(e) {
        e.stopPropagation();
        e.preventDefault();

        const el = e.currentTarget;
        const text = $(el).text().trim();
        const value = $(el).data('value');

        this.$select.val(value);
        this.setComponentData({ text, value });
    },
    setComponentData(payload, { silent = false } = {}) {
        const { value } = payload;
        const { key } = this;

        let { text } = payload;

        this.value = value;

        if (!text) {
            this.$select.val(value);
            text = $('option:selected', this.$select).text().trim();
        }

        if (this.disabled) {
            this.$el.removeClass(this.getDropdownClass('checked'));
        } else {
            this.$el.addClass(this.getDropdownClass('checked'));
        }

        const data = {
            key,
            text,
            value,
        };

        this.updateComponentView();
        this.parent.onSelect(data, { silent });
    },
    scrollToSelectedOption() {
        const selectedOption = $(`.${ACTIVE_LIST_ITEM_CLASS}`, this.$list)[0];

        if (selectedOption) {
            const offset = selectedOption.offsetTop;

            this.$el.find('.dropdown-menu').scrollTop(offset);
        }
    },
    updateComponentView() {
        const newValue = this.$select.val();
        const newText = $('select option:selected', this.$el).text();

        const textContainer = $(`.${this.dropdownSelector}_input`, this.$el);
        const selectedListItem = $(`li[data-value="${newValue}"]`, this.$list);

        textContainer.text(newText);
        this.$list.children().removeClass(ACTIVE_LIST_ITEM_CLASS);
        selectedListItem.addClass(ACTIVE_LIST_ITEM_CLASS);
    }
});

export default BirthdateDropdown;
