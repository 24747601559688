import $ from 'jquery';
import BaseComponent from 'wgnet-awesomejs/base/component';
import Application, {
    _
} from 'wgnet-awesomejs';

import { ERROR_BLOCK_EVENTS } from '../constants';
import { GTM_EVENT_TYPES } from '../fields/error';
import template from './template.html';

const ErrorBlock = BaseComponent.extend({
    name: 'error-block',
    parent: '.js-content',
    errorMessagePrefix: 'registration',
    template,
    initialize(...args) {
        BaseComponent.prototype.initialize.apply(this, args);
        this.dataLayer = window.dataLayer;
        if (this.data.messagePrefix) {
            this.errorMessagePrefix = this.data.messagePrefix;
        }
        this.parent = this.data.parent || this.parent;
        this.$parent = this.$el.parents(this.parent);

        Application.on(ERROR_BLOCK_EVENTS.show, _.bind(this.handleShowEvent, this));
        Application.on(ERROR_BLOCK_EVENTS.hide, _.bind(this.hideError, this));
    },
    handleShowEvent({ errorPrefix, errorSuffix, errors, errorsContext } = {}) {
        this.showErrors(null, { errorPrefix, errorSuffix, errors, errorsContext });
    },
    handleHideEvent() {
        this.hideError();
    },
    showErrors(event, result = {}) {
        const errors = result.errors || [];
        const error = _.extract(errors, '0.__all__', errors[0]);
        const prefix = result.errorPrefix ? `${result.errorPrefix}-` : '';
        const suffix = result.errorSuffix ? `-${result.errorSuffix}` : '';

        if (!error || error === 'not_found') {
            return;
        }

        const errorContext = _.extract(result, `errorsContext.${error}`);

        let errorKey = `${this.errorMessagePrefix}-${prefix}${error}${suffix}`;
        let errorMsg = _.extract(`Settings.errors.${errorKey}`);
        if (!errorMsg) {
            errorKey = `${this.errorMessagePrefix}-${prefix}${error}`;
            errorMsg = _.extract(`Settings.errors.${errorKey}`);

            if (!errorMsg) {
                const defaultErrorKey = `${this.errorMessagePrefix}-no_connection`;

                errorMsg = _.extract(`Settings.errors.${defaultErrorKey}`);
            }
        }
        if (errorContext) {
            errorMsg = errorMsg ? _.template(errorMsg)(errorContext) : errorMsg;
        }

        if (typeof this.dataLayer !== 'undefined') {
            this.dataLayer.push({
                event: 'GAevent',
                eventLabel: errorKey,
                eventCategory: 'Form messages',
                eventAction: GTM_EVENT_TYPES.error
            });
        }

        this.render({ error: errorMsg, type: result.warning ? 'warning' : 'error' });

        if (error === 'empty_field') {
            const firstEmpty = _.find(this.$parent.find('.js-form input:visible'), input => !input.value);
            if (firstEmpty) {
                $(firstEmpty).focus();
            }
        }
    },
    hideError() {
        this.render();
    }
});

export default ErrorBlock;
