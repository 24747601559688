import {
    _
} from 'wgnet-awesomejs';

const THEME_SETTINGS = _.extract('Settings.Registration.themeSettings', {});
if (!THEME_SETTINGS.default) {
    THEME_SETTINGS.default = {
    };
} else {
    THEME_SETTINGS.default.extend = undefined;
}
const THEME_NAME = _.extract('Settings.Registration.themeName', 'default');

const syncThemeSetting = (name) => {
    let theme = THEME_SETTINGS[name];
    if (!theme) {
        theme = THEME_SETTINGS.default;
    }
    if (theme.extend) {
        syncThemeSetting(theme.extend);
    }
    window.Settings.update({
        Registration: {
            themeSetting: theme
        }
    });
    return window.Settings.Registration.themeSetting;
};

export default syncThemeSetting(THEME_NAME);
export { syncThemeSetting };
