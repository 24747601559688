import $ from 'jquery';
import Spinner from 'registration/js/lib/spinner';
import 'url-polyfill';
import URI from 'urijs';
import '@babel/polyfill';

import Application, {
    _
} from 'wgnet-awesomejs';
import BaseView from 'wgnet-awesomejs/base/view';
import mobile from 'wgnet-awesomejs/lib/mobile';
import {
    useSimpleFlow,
    showLoginForm,
    openLoginOnInit
} from 'registration/js/lib/get_splittest_flow';

import pushGaEvent from 'registration/js/lib/googletagmanager/events';
import pushGaEventNew, { GTM_EVENTS } from 'registration/js/lib/googletagmanager/new_events';

import BasicExternalModel, { getSignInExternalData } from './basic_external_model';
import {
    BIRTHDATE_EVENTS,
    EXTERNAL_STARTED_EVENT,
    EXTERNAL_CANCEL_EVENT,
    EXTERNAL_PROVIDER_SETTING,
    EXTERNAL_SWITCH_FORM_EVENT,
    TABS_STATE,
    REDIRECT_ERRORS,
    REGISTRATION_VIEW_EVENTS,
} from './constants';

const ExternalServiceView = BaseView.extend({
    tab: TABS_STATE.REG,
    elPrefix: '.js-registration',
    SPINNER_CANCEL_TIMEOUT: 1000,
    WaitingView: null,
    getSignInExternalData,

    settings() {
        return _.extract('Settings.Registration', {});
    },
    services() {
        return this.settings().externalServices || {};
    },
    get verificationProviders() {
        return this.services().verificationProviders || [];
    },
    get providerNames() {
        return this.services().providerNames || [];
    },
    get formTokenRaw() {
        return _.extract('Settings.Registration.formTokenRaw');
    },
    errorQueryParameter() {
        return this.services().errorQueryParameter;
    },
    renderContext: {
        providers: [],
        verificationProviders: [],
        enableRegButtons: false,
        enableVerifyButtons: false
    },
    templates: {
        services() {},
        serviceComplete() {},
        serviceGettokenMessage() {}
    },
    beforeInitialize(options = {}) {
        this.parent = options.parent;
        this.makeContext();
        this.initModel();
        this.bindEvents();
    },
    makeContext() {
        const providers = this.services().providers;
        const verificationProviders = this.services().verificationProviders;
        const buttons = this.services().providerButtons || [];
        const providerLists = buttons.reduce((data, provider) => {
            if (providers.indexOf(provider) > -1) {
                data.main.push({
                    key: provider,
                    name: this.providerNames[provider]
                });
            } else if (verificationProviders.indexOf(provider) > -1) {
                data.verification.push({
                    key: provider,
                    name: this.providerNames[provider]
                });
            }
            return data;
        }, { main: [], verification: [] });
        this.enebledProviders = [
            ...(providers || []),
            ...(verificationProviders || []),
        ];
        this.renderContext = {
            providers: providerLists.main,
            verificationProviders: providerLists.verification,
            enableRegButtons: providerLists.main.length > 0,
            enableVerifyButtons: providerLists.verification.length > 0
        };
    },
    initModel() {
        const settings = this.settings();
        this.model = new BasicExternalModel({
            game_port: settings.externalProvider.gamePort,
            game: settings.externalProvider.game,
            registration_flow: settings.registrationFlow,
            form_token: settings.formTokenRaw,
            external: settings.externalProvider.code
        }, {
            getDataUrl: settings.getDataUrl,
            oauthAuthorizeUrl: settings.authorizeUrl,
            openIdAuthorizeUrl: settings.openIdAuthorizeUrl,
            openIdProviders: settings.externalServices.openIdProviders
        });
    },
    bindEvents() {
        this.delegateEvents({
            'click .js-registration-external-service-select-provider': 'select',
            'click .js-registration-external-service-cancel': 'onCancel'
        });

        Application.on(EXTERNAL_STARTED_EVENT, this.externalStart, this);
        Application.on(EXTERNAL_SWITCH_FORM_EVENT, this.externalSwitch, this);
        Application.on(
            REGISTRATION_VIEW_EVENTS.EXTERNAL_VIEW.hide,
            _.bind(this.hideExternalView, this)
        );
        Application.on(
            REGISTRATION_VIEW_EVENTS.EXTERNAL_VIEW.show,
            _.bind(this.showExternalView, this)
        );
    },
    hideExternalView() {
        this.$el.addClass('hidden');
    },
    showExternalView() {
        this.$el.removeClass('hidden');
    },
    async getData(provider) {
        try {
            const result = await this.model.getData();
            await this.getDataComplete(result, provider);
        } catch (e) {
            const errors = _.extract(e, 'errors', {});
            const data = _.extract(e, 'data');
            const errorRedirectUrl = _.extract(e, 'redirect_url', '');
            let error;
            if (typeof errors === 'string') {
                error = errors;
            } else {
                error = (errors[Object.keys(errors)[0]] || [])[0];
            }
            if (!error) {
                error = _.extract(e, 'error', 'service_unavailable');
            }
            let errorContext = _.extract(e, 'errors:context.__all__');
            if (!errorContext) {
                const context = _.extract(e, 'errors:context');
                if (context) {
                    errorContext = { [error]: context };
                }
            }
            await this.onFormError(error, errorContext || data, provider, errorRedirectUrl);
            this.stopSpinner();
        } finally {
            this.WaitingView.stopWaiting();
        }
    },
    checkSigned() {
        if (this.settings().externalSigned) {
            this.parent.hideForm();
            this.getData();
        }
    },
    async getDataComplete(result = {}, provider) {
        const timeout = result.status === 'processing';

        if (timeout) {
            this.onFormError(null, 'timeout');
            this.clearExternalData();
        }
        if (this.settings().externalSigned && !timeout) {
            const data = result.data;
            if (data.firstName || data.lastName) {
                const separate = data.firstName && data.lastName ? ' ' : '';
                data.fullName = `${data.firstName || ''}${separate}${data.lastName || ''}`;
            } else if (data.nickname) {
                data.fullName = data.nickname;
            }
            await this.parent.showForm(data, result.status);
            Object.assign(this.renderContext, { result: data });
            this.render('Complete', { provider });
            if (result.last_attempt) {
                this.onFormWarning('last_attempt');
            }

            if (data.birthdate) {
                Application.trigger(BIRTHDATE_EVENTS.set_external, data.birthdate);
            }
        } else {
            this.parent.showForm();
            this.stopSpinner();
            this.render();
        }

        this.toggleHeadings();
    },
    onFieldError() {},
    onFormWarning(code) {
        this.parent.triggerCommonError(this.tab, 'error', {
            errors: [code],
            errorPrefix: this.errorPrefix,
            errorSuffix: this.currentProvider,
            warning: true
        });
    },
    async onFormError(error, errorsContext, provider, errorRedirectUrl) {
        if (!error || error.length === 0) {
            return;
        }
        this.clearExternalData();
        Application.trigger('wgnet.registration.external-service.getdata.error.form', error);

        if (_.isObject(error)) {
            const errorText = _.extract(error, '__all__.0', null);

            if (errorText === 'already_authenticated') {
                this.redirectTo(errorsContext);
                return;
            }
        }

        if (REDIRECT_ERRORS.includes(error) && !this.verificationProviders.includes(provider)) {
            if (errorRedirectUrl) {
                this.redirectTo(errorRedirectUrl);
            } else {
                const redirectUrl = this.settings().authenticationUrl;
                this.redirectTo(redirectUrl);
            }
            return;
        }
        this.parent.triggerCommonError(this.tab, 'error', {
            errors: [error],
            errorsContext,
            errorPrefix: this.errorPrefix,
            errorSuffix: this.currentProvider || provider
        });
        await this.parent.showForm();
        this.cancel();
    },
    errorPrefix: 'external_services',
    onQueryError(error) {
        if (error) {
            this.onFormError(error);
            this.parent.showForm();
            this.render();
        }
    },
    clearExternalData() {
        const settings = this.settings();
        settings.externalSigned = 0;
        settings.externalProvider = {};
        this.toggleHeadings();
    },
    afterInitialize() {
        const settings = this.settings();
        const providerCode = settings.externalProvider.code;
        const query = URI.parseQuery(window.location.search);
        const queryError = query[this.errorQueryParameter()];

        this.render();

        if (query.external && !openLoginOnInit()) {
            Application.trigger(EXTERNAL_STARTED_EVENT, query.external);
        } else if (settings.externalSigned && providerCode) {
            this.parent.onTabSwitch(null, this.tab);
            this.externalContinue(providerCode);
        } else if (providerCode) {
            Application.trigger(EXTERNAL_STARTED_EVENT, providerCode);
        } else if (queryError) {
            this.onQueryError(queryError);
        }
    },
    externalContinue(provider) {
        if (mobile().useMobile && useSimpleFlow()) {
            window.Settings.update({
                Registration: {
                    registrationOptions: {
                        nameFlow: null
                    }
                }
            });
        }
        const selector = `${this.elPrefix}-external-service-select-provider__${provider}`;
        this.currentProvider = provider;

        this.parent.onTabSwitch(null, this.tab);

        Application.trigger('wgnet.registration.external-service.getdata.started', {
            provider,
            providerName: this.providerNames[provider]
        });
        this.initExternalServiceMessage(this.$(selector), provider);
        this.getData(provider);
    },
    externalSwitch(result) {
        this.parent.onTabSwitch(null, this.tab);
        if (!result.data) {
            return;
        }
        const settings = this.settings();
        settings.externalSigned = 1;
        settings.externalProvider.code = result.provider;
        settings.externalProvider.name = this.providerNames[result.provider];
        this.getDataComplete(result, result.provider);
    },
    async externalStart(provider) {
        const providerName = _.contains(this.enebledProviders, provider) ? provider : null;
        const selector = `${this.elPrefix}-external-service-select-provider__${provider}`;

        if (providerName) {
            if (this.tab === TABS_STATE.REG) {
                const externalData = this.getSignInExternalData(providerName);

                if (externalData) {
                    const settings = this.settings();
                    settings.externalSigned = 1;
                    settings.externalProvider.code = providerName;
                    settings.externalProvider.name = providerName;
                    await this.getDataComplete({
                        status: 'OK',
                        data: externalData,
                    }, provider);
                    return providerName;
                }
            }
            this.initExternalServiceMessage(this.$(selector), provider);
            this.renderContext.provider = providerName;
            this.renderContext.providerName = this.providerNames[providerName];
            try {
                await this.model.getToken(providerName);
            } catch (e) {
                let error = _.extract(e, 'errors.external', e.errors);
                if (!error) {
                    error = _.extract(e, 'error', 'service_unavailable');
                }
                const errorContext = _.extract(e, 'extras.next_url', undefined);
                this.onFormError(error, errorContext, provider);
            }

            // cancel external processing if click back button in safary on SN page
            setTimeout(() => this.cancel(), this.SPINNER_CANCEL_TIMEOUT);
        }
        return providerName;
    },
    getContext(context = {}) {
        return BaseView.prototype.getContext.call(this, Object.assign(
            context,
            {
                simpleMode: useSimpleFlow(),
                showTitleText: _.extract('Settings.Registration.gameModificator') !== 'wows',
                branding: _.extract('Settings.Registration.branding'),
            }
        ));
    },
    render(type, { provider } = {}) {
        const rendered = this.template(type, { provider })(this.getContext(this.renderContext));
        this.$el.html(rendered);
        return rendered;
    },
    template(type, { provider } = {}) {
        let template;
        if (provider) {
            template = this.templates[`service${provider[0].toUpperCase()}${provider.substr(1)}${type || 's'}`];
        }
        if (!template) {
            template = this.templates[`service${type || 's'}`];
        }
        return template;
    },
    async snButtonGaEvent(provider) {
        await pushGaEvent('SN_CLICK', 'reg', this.providerNames[provider]);
    },
    async snButtonGaEventNew(provider) {
        await pushGaEventNew({ event: GTM_EVENTS.SN_CLICK, label: ['reg', provider] });
    },
    async select(event = {}) {
        event.preventDefault();
        const $el = $(event.currentTarget);
        const provider = $el.data('provider');
        if (showLoginForm()) {
            await this.snButtonGaEvent(provider);
        }
        await this.snButtonGaEventNew(provider);
        this.initExternalServiceMessage($el);
        this.externalStart(provider);
        return Promise.resolve();
    },
    onCancel() {
        this.clearFormError();
        this.cancel();
    },
    clearFormError() {
        this.parent.triggerCommonError(this.tab, 'valid');
    },
    cancel() {
        this.render();
        _.extract(EXTERNAL_PROVIDER_SETTING, {}).code = undefined;
        _.extract('Settings.Registration').externalSigned = 0;
        Application.trigger(EXTERNAL_CANCEL_EVENT);
        return false;
    },
    initExternalServiceMessage($el, provider) {
        const external = this.providerNames[provider];
        const context = this.getContext({ external, provider });
        this.render('GettokenMessage', context[0]);
        this.initBaseThemeSpin();
    },
    stopSpinner() {
        if (!this.spinner) {
            return;
        }
        this.spinner.stop();
        const $wrapper = this.$(`${this.elPrefix}-external-service-select-wrapper`);

        $wrapper.removeClass('b-social-registration_item__waiting');
    },
    initBaseThemeSpin() {
        const $spinContainerSelector = $('.js-spin');

        this.spinner = this.spinner || new Spinner({
            modifiers: ['default', 'universal']
        });

        this.spinner.spin($spinContainerSelector.get(0));
    },
    initSpin($el) {
        this.stopSpinner();
        const isHiddenProvider = $el.length === 0;
        const $spinner = isHiddenProvider ? this.$('.js-registration-hidden_provider_spinner') : $el;
        const $spinnerContainer = isHiddenProvider ? $spinner : $spinner.find('.js-registration-signin-external-service-select-ico');
        this.spinner = new Spinner(
            isHiddenProvider ? this.hiddenSpinnerOptions : this.spinnerOptions
        );
        const $wrapper = $spinner.parents(`${this.elPrefix}-external-service-select-wrapper`);

        $wrapper.addClass('b-social-registration_item__waiting');

        if (isHiddenProvider) {
            $wrapper.removeClass('hidden');
        }

        this.spinner.spin($spinnerContainer.get(0));
    },
    spinnerOptions: {
        modifiers: ['big', 'dark']
    },
    toggleHeadings() {
        const $heading = $(`${this.elPrefix}-heading`);
        const $headingExternal = $(`${this.elPrefix}-external-heading`);

        if (useSimpleFlow()) {
            $heading.addClass('hidden');
            $headingExternal.removeClass('hidden');
        } else {
            $heading.removeClass('hidden');
            $headingExternal.addClass('hidden');
        }
    },
});

export default ExternalServiceView;
