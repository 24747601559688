import { _ } from 'wgnet-awesomejs';

export const EVENTS = {
    FORM_STATE: {
        action: 'Change Form State button click',
        events: {
            regToLogin: 'State changed from Reg to Login',
            loginToReg: 'State changed from Login to Reg'
        }
    },
    REG_FORM_INTERACTION: {
        action: 'Registration Interaction Funnel',
        events: {
            eula: 'Check-box Agreement ', // + checked/unchecked
            subscr: 'Check-box Marketing ', // + checked/unchecked
            complete: 'Registration Funnel Complete',
            loginFocus: 'Field login focus - Reg Funnel progress',
            nameFocus: 'Field name focus - Reg Funnel progress',
            passwordFocus: 'Field password focus - Reg Funnel progress',
            re_passwordFocus: 'Field retype password focus - Reg Funnel progress',
            bonus_codeFocus: 'Field bonus code focus - Reg Funnel progress',
            captchaFocus: 'Field captcha focus - Reg Funnel progress'
        }
    },
    RECOVERY_INTERACTION: {
        action: 'Recover account interaction',
        events: {
            linkClick: 'Recover account link click'
        }
    },
    LOGIN_FORM_REMEMBER_ME: {
        action: 'Login Remember me checkbox',
        events: {
            checked: 'click',
            unchecked: 'unclick'
        }
    },
    LOGIN_FORM_INTERACTION: {
        action: 'Login Interaction Funnel',
        events: {
            download: 'Download button click',
            loginFocus: 'Field login focus - Login Funnel progress',
            passwordFocus: 'Field password focus - Login Funnel progress',
            captchaFocus: 'Field captcha focus - Login Funnel progress',
            complete: 'Login Funnel Complete'
        }
    },
    VALIDATION: {
        action: 'Form Validation Error Message',
        events: {
            login: 'Login Form / ', // + error code
            reg: 'Registration Form / ' // + error code
        }
    },
    SN_CLICK: {
        action: 'Social button click',
        events: {
            login: 'Login Form / ', // + provider name
            reg: 'Registration Form / ' // + provider name
        }
    },
    INVITE_CODE_INTERACTION: {
        action: 'Invite code field interaction',
        events: {
            click: 'Invite code link click'
        }
    },
    OUTBOUND: {
        action: 'Outbound link click',
        events: {
            click: '' // + url
        }
    }
};

const CATEGORY = game => `New-2019-${game}-RegForm-Interaction`;

export default function pushGaEvent(actionName, eventName, customMessage) {
    if (!window.dataLayer) {
        return Promise.resolve();
    }
    const { action, events } = EVENTS[actionName] || {};
    if (!events) {
        return Promise.resolve();
    }
    let label = events[eventName];
    if (label === undefined) {
        return Promise.resolve();
    }
    const game = _.extract('Settings.Registration.gameModificator', 'none');
    if (customMessage) {
        label = `${label}${customMessage}`;
    }
    return new Promise(resolve => {
        const event = {
            event: 'autoEvent',
            eventCategory: CATEGORY(game.toUpperCase()),
            eventAction: action,
            eventLabel: label,
            eventCallback: () => resolve(),
            eventTimeout: 2000
        };
        window.dataLayer.push(event);
        resolve(null);
    });
}
