/* eslint-disable import/prefer-default-export */
import { _ } from 'wgnet-awesomejs';
import mobile from 'wgnet-awesomejs/lib/mobile';

import BonusCodeOptions from './bonus_code';
import CountryCodeOptions from './country_code';

const bonusCodeOptions = new BonusCodeOptions(
    _.extract('Settings.Registration.fields.bonus_code'),
    { mobile: mobile().useMobile },
);
const countryCodeOptions = new CountryCodeOptions(
    _.extract('Settings.Registration.forbiddenCountries')
);

export {
    bonusCodeOptions,
    countryCodeOptions,
};
