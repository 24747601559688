import AjaxRequest from '@wgn/ajaxrequest';

import SSOService from './SSOService';

class AuthService {
    static goToUrl(url) {
        window.location = url;
    }

    static errorHandler(error) {
        const redirectUrl =
            (error && error.response && error.response.data && error.response.data.error_url)
            || (error && error.response && error.response.data && error.response.data.done_url);
        if (redirectUrl) {
            AuthService.goToUrl(redirectUrl);
        }
    }

    constructor({ ssoNotifyTimeout } = {}) {
        this.ssoNotifyTimeout = ssoNotifyTimeout;
    }

    async authenticate(url) {
        try {
            const authResponse = await AjaxRequest.submit({}, {
                url,
                method: 'GET',
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                },
            });
            const sso = new SSOService(
                authResponse && authResponse.data && authResponse.data.sso_url,
                this.ssoNotifyTimeout
            );
            await sso.notify();

            const redirectUrl =
                (authResponse && authResponse.data && authResponse.data.done_url)
                || (authResponse && authResponse.data && authResponse.data.next_url);
            if (redirectUrl) {
                AuthService.goToUrl(redirectUrl);
            }
        } catch (err) {
            AuthService.errorHandler(err);
        }
    }
}

export default AuthService;
