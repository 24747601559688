import AjaxRequest from '@wgn/ajaxrequest';

const SSO_DEFAULT_TIMEOUT = 5;

class SSOService {

    static createContainer() {
        const el = document.createElement('div');
        el.style.position = 'absolute';
        el.style.top = '-30px';
        document.body.appendChild(el);
        return el;
    }

    constructor(ssoUrl, timeout) {
        this.ssoUrl = ssoUrl;
        this.timeout = Number.isInteger(timeout) ? timeout : SSO_DEFAULT_TIMEOUT;
        this.createNotifyImage = this.createNotifyImage.bind(this);
    }

    createNotifyImage(container, url) {
        return new Promise(resolve => {
            const img = document.createElement('img');
            img.style.height = 0;
            img.style.width = 0;
            img.onerror = resolve;
            img.onload = resolve;
            img.src = url;
            container.appendChild(img);
            setTimeout(resolve, this.timeout * 1000);
        });
    }

    async notify() {
        if (!this.ssoUrl) {
            return false;
        }

        const response = await AjaxRequest.submit({}, {
            url: this.ssoUrl,
            method: 'GET',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
            },
        });

        const ssoUrls = response && response.data && response.data.sso_urls;
        if (!(ssoUrls && ssoUrls.length)) {
            return false;
        }

        const container = SSOService.createContainer();
        return Promise.all(ssoUrls.map(url => this.createNotifyImage(container, url)))
            .finally(() => {
                document.body.removeChild(container);
            });
    }
}

export default SSOService;
