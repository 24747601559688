import Application, {
    _, $,
} from 'wgnet-awesomejs';

import { ERROR_BLOCK_EVENTS, PARENTAL_EMAIL_FIELD_EVENTS } from 'registration/js/base/constants';

import BaseInput from './input';

const ParentalEmailField = BaseInput.extend({
    name: 'parental-email-field',
    fieldName: 'parental_email',

    afterInitialize(...args) {
        BaseInput.prototype.afterInitialize.apply(this, args);

        this.$form = this.$el.parents('form');

        this.hide();
        this.initEvents();
    },
    initEvents() {
        this.$form.on(PARENTAL_EMAIL_FIELD_EVENTS.show, _.bind(this.show, this));
        this.$form.on(PARENTAL_EMAIL_FIELD_EVENTS.hide, _.bind(this.hide, this));
        this.$form.on(PARENTAL_EMAIL_FIELD_EVENTS.clear, _.bind(this.clear, this));
    },
    clear() {
        this.$el.find(`input[name=${this.fieldName}]`).val('');
    },
    hide() {
        this.$el.hide();
    },
    show() {
        this.$el.show();
        setTimeout(() => {
            $('input', this.$el).focus();
            Application.trigger(`${this.fieldName}.valid`);
            Application.trigger(ERROR_BLOCK_EVENTS.hide);
        });
    },
});

export default ParentalEmailField;
