import jscAdd from 'ehogan-loader/jscmanager';
import {
    _
} from 'wgnet-awesomejs';

import setupEvents from 'wgnet-awesomejs/lib/elements_init';

import BaseRegistrationFormView from 'registration/js/base/registration_form_view';

import template from 'registration/templates/simple/form.html';

import {
    nameRequiredValidator,
    nameMinLengthValidator,
    nameMaxLengthValidator,
    namePatternValidator,
    nameServerPrevalidation,
    eulaRequiredValidator
} from 'registration/js/lib/validators';

import abTestWrapper from 'registration/js/lib/abtest_trigger_wrapper';

import ErrorBlock from './error_block';
import Model from './form_model';

import DescriptionField from './fields/description';
import NameField from './fields/name';
import SubmitButton from './fields/submit';
import ErrorField from './fields/error';
import EulaField from './fields/eula';


jscAdd([
    ErrorBlock,
    DescriptionField,

    ErrorField,
    NameField,
    SubmitButton,
    EulaField
]);

const NewBasicFormView = BaseRegistrationFormView.extend({
    template,
    setValidators() {
        this.model.validators = [];
        this.model.validators.push(
            nameRequiredValidator,
            namePatternValidator,
            nameMinLengthValidator,
            nameMaxLengthValidator,
            nameServerPrevalidation,
            eulaRequiredValidator
        );
    },
    Model(...args) {
        return new Model(...args);
    },
    afterInitialize() {
        this.render();
    },
    onRegistrationError(result = {}, prevalidate) {
        const errors = this.getErrors(result);
        const keys = this.getErrorKeys(errors);
        if (keys.length > 0) {
            for (let i = keys.length - 1; i >= 0; i -= 1) {
                const key = keys[i];
                const error = errors[key];
                if (key === '__all__' && error[0] === 'conflict_login_external') {
                    const authUrl = _.extract('Settings.Registration.authenticationUrl');
                    this.redirectTo(authUrl);
                    return;
                }
            }
        }
        BaseRegistrationFormView.prototype.onRegistrationError.call(this, result, prevalidate);
    },
    show(...args) {
        BaseRegistrationFormView.prototype.show.apply(this, args);
        this.$el.removeClass('b-form__transparent');
        this.formPrevalidate();
        setupEvents({
            fields: this.$('.input-field_field'),
            wrapperBlock: 'input-field'
        });
    },
    onRegistrationSuccess: abTestWrapper(
        BaseRegistrationFormView.prototype.onRegistrationSuccess,
        '^.*registration success.*$'
    )
});


export default NewBasicFormView;
