import { _ } from 'wgnet-awesomejs';

export function generateDaysList(daysQuantity = 31) {
    const defaultDaysList = _.range(1, daysQuantity + 1, 1);
    const daysList = defaultDaysList.map(title => ({
        title,
        value: title.toString().padStart(2, '0')
    }));

    return daysList;
}

export function generateMonthsList() {
    const monthsNamesList = _.extract('Settings.messages.registration-months_list');

    if (!monthsNamesList) {
        return [];
    }

    const monthsList = monthsNamesList.map((title, i) => ({
        title,
        value: (i + 1).toString().padStart(2, '0')
    }));

    return monthsList;
}

export function generateYearsList(start, end) {
    const finalYear = end || new Date().getFullYear();
    const yearsRange = _.range(finalYear, start - 1, -1);
    const yearsList = yearsRange.map(title => ({
        title,
        value: title
    }));

    return yearsList;
}

export default {
    generateDaysList,
    generateMonthsList,
    generateYearsList,
};
