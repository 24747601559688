import Application, {
    _, $
} from 'wgnet-awesomejs';

import BaseView from 'wgnet-awesomejs/base/view';
import mobile from 'wgnet-awesomejs/lib/mobile';
import pushGaEvent from 'registration/js/lib/googletagmanager/events';
import pushGaEventNew, { GTM_EVENTS } from 'registration/js/lib/googletagmanager/new_events';
import { useSimpleFlow, useIncompleteFlow, showLoginForm } from 'registration/js/lib/get_splittest_flow';
import pause from 'registration/js/lib/pause';
import { countryCodeOptions } from 'registration/js/lib/fieldsOptions';
import { getPasswordFlow, getNameFlow } from 'registration/js/lib/get_field_flow';
import AuthService from 'registration/js/lib/services/AuthService';
import {
    loginRequiredValidator,
    loginMinLengthValidator,
    loginMaxLengthValidator,
    emailPatternValidator,
    loginEmailDomainCheckValidator,
    loginDisabledDomainsValidator,

    nameRequiredValidator,
    nameMinLengthValidator,
    nameMaxLengthValidator,
    namePatternValidator,
    nameServerPrevalidation,

    passwordRequiredValidator,
    passwordMismatchValidator,
    passwordMinLengthValidator,
    passwordMaxLengthValidator,
    passwordPatternValidator,
    passwordMatchesLoginValidator,
    passwordMatchesNameValidator,
    repasswordRequiredValidator,

    captchaPatternValidator,
    captchaRequiredValidator,

    countryRequiredValidator,

    bonusCodeMaxLengthValidator,
    bonusCodePatternValidator,
    bonusCodeRequiredValidator,

    eulaRequiredValidator,
    ERR_EMPTY_FIELD,
    ERR_SERVER,
    ERR_UNKNOWN,
    ERR_COUNTRY_CONFLICT,
} from 'registration/js/lib/validators';
import MinorsService from '@/lib/services/MinorsService';
import RegistrationFormModel from './registration_form_model';
import {
    BIRTHDATE_EVENTS,
    EMAIL_SETTING,
    ERROR_BLOCK_EVENTS,
    EXTERNAL_CODE_SETTING,
    GA_LENGTH,
    KEYCODES,
    NAME_SUGGESTION_ERRORS,
    PARENTAL_EMAIL_FIELD_EVENTS,
    BIRTHDATE_FIELD_EVENTS,
    REGISTRATION_FORM_VIEWS,
    STRICT_HINT_ENABLED_SETTING,
    REGISTRATION_VIEW_EVENTS,
} from './constants';

export const HTTP_CODES_ERROR_MESSAGE_MAPPING = {
    0: 'no_connection',
    202: 'timeout',
    403: 'bad_response',
    502: 'no_connection',
    500: 'no_connection'
};

const PREVENT_PREVALIDATE_KEYS = [
    KEYCODES.ENTER,
    KEYCODES.SHIFT,
    KEYCODES.TAB
];

const BaseRegistrationFormView = BaseView.extend({
    template() {},
    minorsService: MinorsService.get(),
    Model: RegistrationFormModel,

    view: REGISTRATION_FORM_VIEWS.main,
    strictRegistrationData: null,
    externalBirthdateData: null,

    getFieldView(fieldName) {
        if (['__all__', 'captcha'].includes(fieldName)) {
            return this.view;
        }
        if (['parental_email'].includes(fieldName)) {
            return REGISTRATION_FORM_VIEWS.parental_email;
        }
        return REGISTRATION_FORM_VIEWS.main;
    },

    basicFormOptions() {
        return {
            view: this,
            featureKeyPrefix: 'Settings.Registration.NewChallenge',
            RiddlerLibPath: 'registration/js/riddler/',
            Url: _.extract('Settings.Registration.newUrl')
        };
    },
    showCountryCodeConflictError() {
        if (!countryCodeOptions.userCountryAllowed) {
            Application.trigger(ERROR_BLOCK_EVENTS.show, {
                errors: [
                    {
                        __all__: ERR_COUNTRY_CONFLICT
                    }
                ]
            });
        }
    },

    beforeInitialize(options = {}) {
        if (mobile().useMobile) {
            window.Settings.update({
                Registration: {
                    fields: {
                        password: {
                            flow: 'no_repeat'
                        },
                        name: {
                            flow: useSimpleFlow() ? 'default' : 'generate'
                        }
                    },
                }
            });
        }
        this.parent = options.parent;
        this.model = new this.Model({}, this.basicFormOptions());
        this.setValidators({ loginDisabled: !!_.extract(EMAIL_SETTING, false) });
        this.bindModelEvents();

        const debounceTimeout = options.debounceTimeout || 100;
        this.formPrevalidate = _.throttle(this.formPrevalidate, debounceTimeout);
        this.mobileClear = _.throttle(this.mobileClear, debounceTimeout * 3);

        Application.on(BIRTHDATE_EVENTS.set_external, _.bind(this.setExternalBirthdate, this));
    },
    bindModelEvents() {
        this.model.on('invalid', (model, errors, options = {}) => {
            if (options.onValidateError) {
                options.onValidateError(errors);
            } else {
                this.onRegistrationError({
                    responseJSON: {
                        errors
                    }
                });
            }
            this.stopWait();
        }, this);
        this.model.on('update.challenge.captcha', () => {
            this.$form.trigger('captcha.show', { url: this.model.captchaUrl });
            this.stopWait();
        }, this);
        this.model.on('update.challenge.pow', () => {
            this.$form.trigger('captcha.hide');
        }, this);
        this.model.on('login.strict.error', this.showEmailStrictPopup, this);

        this.model.on('change:country_code', (model, value) => {
            const prevValue = model.previous('country_code') || '';
            const nextValue = value || '';
            if (prevValue === nextValue) return;
            if (this.view === REGISTRATION_FORM_VIEWS.parental_email) return;
            const minorRestrictions = this.minorsService.isRestrictionEnabled(value);
            if (minorRestrictions) {
                this.$form.trigger(BIRTHDATE_FIELD_EVENTS.show);
            } else {
                this.$form.trigger(BIRTHDATE_FIELD_EVENTS.hide);
            }
        });
    },
    setValidators(options = {}) {
        this.model.validators = [];
        if (countryCodeOptions.visible) {
            this.model.validators.push(
                countryRequiredValidator,
            );
        }
        if (!options.loginDisabled && !this.simpleMode && !useIncompleteFlow()) {
            this.model.validators.push(
                loginRequiredValidator,
                emailPatternValidator,
                loginMinLengthValidator,
                loginMaxLengthValidator,
                loginDisabledDomainsValidator,
                loginEmailDomainCheckValidator
            );
        }
        this.model.validators.push(
            captchaPatternValidator,
            captchaRequiredValidator,

            bonusCodeMaxLengthValidator,
            bonusCodePatternValidator,
            bonusCodeRequiredValidator,

            eulaRequiredValidator
        );

        this.setNameFlow();
        this.setPasswordFlow();
    },
    renamedFields: {
        login: 'login',
        password: 'password'
    },
    setFormToModel(form, set, reset) {
        const formDataArray = this.$(form).serializeArray();
        if (countryCodeOptions.visible) {
            if (!formDataArray.find(({ name }) => name === 'country_code')) {
                formDataArray.push({ name: 'country_code', value: '' });
            }
        }
        const formData = {};
        _.each(
            formDataArray,
            field => formData[this.renamedFields[field.name] || field.name] = field.value
        );

        if (set && this.model) {
            if (_.isFunction(this.model.set)) {
                if (reset) {
                    this.model.clear();
                }
                this.model.set(formData);
            } else {
                _.extend(this.model, formData);
            }
        }
        return formData;
    },
    hide() {
        this.$el.addClass('hidden');
    },
    async show(data = {}, error) {
        data.email = error !== 'login_is_required' ? data.email : undefined;
        if (!data.nickname && data.fullName) {
            data.nickname = data.nickname || data.fullName.replace(' ', '_');
        }
        const loginDisabled = data.email ? '1' : '';
        this.setValidators({ loginDisabled });
        const nameValidate = this.model.validate(
            { name: data.nickname },
            { prevalidate: true }
        ).name;
        if (nameValidate && nameValidate.length > 0) {
            let nameError = {};
            try {
                nameError = await nameValidate[0];
            } catch (e) {
                console.error(e);
            }
            if (NAME_SUGGESTION_ERRORS.indexOf(nameError.error) > -1) {
                if (
                    !nameError.suggestions
                    || nameError.suggestions.length === 0
                ) {
                    data.nickname = '';
                }
            } else if (typeof nameError === 'string') {
                data.nickname = '';
            }
        }
        this.render(Object.assign({ loginDisabled }, data));
        let focusField = data.email ? 'name' : 'login';
        if (countryCodeOptions.visible) {
            focusField = 'country_code';
        }
        this.$form.trigger(`${focusField}.set.focus`);
        this.formPrevalidate();
        this.$el.removeClass('hidden');
    },
    bindElements() {
        this.$form = this.$('.js-registration-form');
        this.$throbbingElement = this.$('.js-registration-throbbing-element');
        this.delegateEvents({
            'focus input': 'focusInput',
            'blur input': 'blurInput',
            'change input': 'formPrevalidate',
            'form.prevalidate': 'formPrevalidate',
            'login.edit.enabled': 'setValidators',
            'login.edit.disabled': _.bind(this.setValidators, this, { loginDisable: true }),
            'suggestion.login': 'setSuggestionValue',
            'submit form': 'registrationStart',
            '__all__.error': 'showErrorBlock',
            '__all__.valid': 'hideErrorBlock',
        });
        if (!mobile().useMobile) {
            this.delegateEvents({ 'keyup input': 'formPrevalidate' });
        }
    },
    showErrorBlock(event, error) {
        Application.trigger(ERROR_BLOCK_EVENTS.show, error);
    },
    hideErrorBlock() {
        Application.trigger(ERROR_BLOCK_EVENTS.hide);
    },
    afterInitialize() {
        this.render();
        this.model.initializeChallenge();
    },
    render(...args) {
        BaseView.prototype.render.apply(this, args);
        this.bindElements();
        if (this.model.activeChallengeType === 'captcha') {
            this.$form.trigger('captcha.show', { url: this.model.captchaUrl });
        }
    },
    getContext(context = {}) {
        const nameFlow = getNameFlow();
        let simpleMode;
        if (context.email) {
            simpleMode = useSimpleFlow();
        } else {
            simpleMode = useIncompleteFlow();
        }
        if (nameFlow !== 'generate' || simpleMode) {
            context.nameEnabled = true;
        }
        context.simpleMode = simpleMode;
        context.initialMinorsModeEnabled = this.minorsService.isRestrictionEnabled();
        context.externalBirthdateData = this.externalBirthdateData;
        context.countryFieldShown = countryCodeOptions.visible;

        return BaseView.prototype.getContext.call(this, context);
    },
    mobileClear(target) {
        this.$form.trigger(`${target.name}.clear`);
    },
    formPrevalidate(event = {}) {
        const key = event.keyCode;
        if (PREVENT_PREVALIDATE_KEYS.indexOf(key) > -1) {
            return;
        }
        if (event.type === 'change' && $(event.currentTarget).hasClass('js-captcha-input')) {
            return;
        }
        this.setFormToModel(this.$form, true);

        const errors = this.model.validate(this.model.attributes, {
            prevalidate: true,
            hasStrictRegistrationData: Boolean(this.strictRegistrationData),
        });

        if (mobile().useMobile && event.target) {
            this.mobileClear(event.target);
            return;
        }
        if (errors) {
            this.onRegistrationError({
                responseJSON: {
                    errors
                }
            }, true);
        } else {
            this.setValidFields({});
        }
    },
    startWait() {
        if (this.waitingStarted) {
            return true;
        }
        this.$form.trigger('spinner.start.base');
        this.waitingStarted = true;
        return false;
    },
    stopWait() {
        this.$form.trigger('spinner.stop.base');
        this.waitingStarted = false;
    },
    registrationStart(event) {
        if (this.strictRegistrationData) {
            const { login, suggested } = this.strictRegistrationData;

            this.strictRegistrationStart(login, suggested)
                .catch(_.bind(this.onRegistrationError, this));

            return false;
        }

        pushGaEventNew({ event: GTM_EVENTS.REG_FORM_SUBMIT });

        const strictHintDisabled = !_.extract(STRICT_HINT_ENABLED_SETTING, false);

        if (event && event.preventDefault) {
            event.preventDefault();
            event.stopPropagation();
        }
        if (this.startWait()) {
            return false;
        }

        $('button[type=submit]', this.$el).blur();

        this.$form.trigger('captcha.valid');
        this.$form.trigger('eula.valid');
        this.$form.trigger('__all__.valid', {});
        this.setFormToModel(this.$form, true);
        this.model.set('external', _.extract(EXTERNAL_CODE_SETTING, ''));
        this.model.set('ga_clientid', this.getGaClientId(window.ga));

        const teClientIdKey = _.extract('Settings.Registration.TEClientIdCookie');
        if (teClientIdKey) {
            const teclientid = window.$.cookie(teClientIdKey);
            if (teclientid) {
                this.model.set('te_clientid', teclientid);
            }
        }

        this.model.save({}, {
            success: this.onRegistrationSuccess,
            error: this.onRegistrationError,
            context: this,
            presaveValidate: true,
            ignoreLoginStrict: strictHintDisabled
        });
        return false;
    },
    SuggestionDialogView: BaseView,
    showEmailStrictPopup(data) {
        this.emailEditView = this.emailEditView || new this.SuggestionDialogView({
            parent: this
        });
        this.emailEditView.show(data);
    },
    getGaClientId(ga) {
        const requiredTid = _.extract('Settings.Registration.TrackingId');
        const gaLength = _.extract('Settings.Registration.GALength');

        if (ga === undefined || !ga.getAll) {
            return undefined;
        }
        const trackers = ga.getAll();
        trackers.forEach((tracker) => {
            const trackingId = tracker.get('trackingId');
            if (trackingId === requiredTid) {
                this.currentTracker = tracker;
            }
        });
        if (this.currentTracker === undefined) {
            return undefined;
        }

        const gid = this.currentTracker.get('_gid');
        const cid = this.currentTracker.get('clientId');
        if (gaLength === GA_LENGTH.LONG) {
            if (gid === undefined) {
                return undefined;
            }
            return `${gid}-${cid}`;
        }

        return cid;
    },
    setSuggestionValue(event, suggestion = {}) {
        this.model.set(suggestion);
    },
    strictRegistrationStart(login, suggested) {
        this.strictRegistrationData = {
            login,
            suggested,
        };

        if (this.startWait()) {
            return Promise.resolve();
        }
        this.setFormToModel(this.$form, true);
        const suggestionEntered = this.model.get('login');
        this.model.set({
            login,
            eula: this.model.get('eula'),
            subscr: this.model.get('subscr'),
            suggestion_entered: suggestionEntered.split('@')[1],
            suggestion_used: login.split('@')[1],
            suggestion_type: 'hard',
            suggestion_suggested: suggested
        });
        return new Promise((success, error) => {
            // if model has validation errors, success/error callbacks never called
            this.model.save({}, {
                success,
                error: (...args) => {
                    this.stopWait();
                    error(...args);
                },
                context: this,
                presaveValidate: true,
                ignoreLoginStrict: true,
                strictValidate: true
            });
        }).then((...args) => this.onRegistrationSuccess(...args));
    },
    setValidFields(errors) {
        const exists = Object.keys(this.model.toJSON());
        for (let i = 0; i < exists.length; i += 1) {
            const exist = exists[i];
            const error = errors[exist];
            const isErrorField = _.isArray(error) && _.isString(error[0]);
            if (!isErrorField) {
                this.$form.trigger(`${exist}.valid`);
            }
        }
    },
    errorGaEvent(error) {
        pushGaEvent('VALIDATION', 'reg', error);
    },
    pushErrorGaEvent(error) {
        pushGaEventNew({
            event: GTM_EVENTS.REG_FORM_VALIDATION.error,
            label: error
        });
    },
    async onRegistrationError(result = {}, prevalidate) {
        try {
            result.responseJSON = result.responseJSON || JSON.parse(result.responseText);
        } catch (err) {
            this.stopWait();
            this.$form.trigger('__all__.error', { errors: [ERR_SERVER] });
            return;
        }
        const statusCode = result.status;
        const errorMessage = HTTP_CODES_ERROR_MESSAGE_MAPPING[statusCode];

        this.stopWait();
        if (statusCode === 403) {
            window.location.href = window.location.href;
            return;
        }
        if (errorMessage) {
            this.$form.trigger('__all__.error', { errors: [errorMessage] });
            return;
        }

        const errors = this.getErrors(result);
        const keys = this.getErrorKeys(errors);

        const isParentalEmailErrorPreset = keys.includes('parental_email');
        const isParentalEmailError = isParentalEmailErrorPreset && keys.length === 1;

        if (!prevalidate && !isParentalEmailError) {
            this.strictRegistrationData = null;
        }

        this.setValidFields(errors);
        if (mobile().useMobile) {
            await pause(50);
        }

        if (keys.length > 0) {
            const prevView = this.view;

            if (!prevalidate) {
                const fieldsViews = keys.map(field => this.getFieldView(field));
                // change view if current view hasn't errors
                if (!fieldsViews.some(view => view === this.view)) {
                    this.updateView(fieldsViews[0]);
                }
            }

            keys.forEach(key => {
                const error = errors[key];
                if (!error) {
                    return;
                }

                if (!prevalidate) {
                    const gaEventError = `${key} ${error[0]}`;
                    this.pushErrorGaEvent(gaEventError);

                    if (showLoginForm()) {
                        this.errorGaEvent(gaEventError);
                    }
                }
                if (key === '__all__' && error[0] === 'already_authenticated') {
                    this.redirectTo(result.responseJSON.extras.next_url);
                }
                if (key === 'bonus_code' && mobile().useMobile) {
                    this.$form.trigger('__all__.error', { errors: ['bonus_code_cant_apply'] });
                }
                if (key === 'tid') {
                    this.$form.trigger('__all__.error', { errors: error });
                }

                const errorData = {
                    errors: error,
                    field: key,
                    prevalidate
                };

                if (key === 'birthdate') {
                    errorData.errorPrefix = 'birthdate';
                }

                // if got parental_email error on parental_email view
                // than transform it to __all__.empty error
                // to show error on form error block
                if (
                    prevView === this.view && // don't trigger if parental_email form just shown
                    this.view === REGISTRATION_FORM_VIEWS.parental_email &&
                    key === 'parental_email' &&
                    Array.isArray(error) && error.length === 1 && error[0] === ERR_EMPTY_FIELD
                ) {
                    this.$form.trigger('__all__.error', { errors: [ERR_EMPTY_FIELD] });
                    return;
                }

                this.$form.trigger(`${key}.error`, errorData);
            });

            if (!prevalidate) {
                this.focusOnError([...keys]);
            }
        } else {
            this.$form.trigger('__all__.error', { errors: [ERR_UNKNOWN] });
        }
    },
    getErrors(result) {
        return _.extract(result, 'responseJSON.errors') ||
               (result.responseText ? JSON.parse(result.responseText) : {}).errors;
    },
    getErrorKeys(errors) {
        return Object.keys(errors);
    },
    focusOnError(keys) {
        let focusField = keys.shift();
        if (focusField === 'login_strict') {
            focusField = keys.shift();
        }
        this.$form.trigger(`${focusField}.set.focus`);
    },
    async onRegistrationSuccess(result = {}) {
        await pushGaEventNew({ event: GTM_EVENTS.REG_FORM_VALIDATION.success });

        this.$form.trigger(BIRTHDATE_EVENTS.clear);
        this.$form.trigger(PARENTAL_EMAIL_FIELD_EVENTS.clear);

        this.strictRegistrationData = null;

        if (result.success_url) {
            if (_.extract('Settings.Registration.externalSigned')) {
                const auth = new AuthService();
                auth.authenticate(result.success_url);
                return;
            }
            this.redirectTo(result.success_url);
        } else {
            this.stopWait();
        }
    },
    focusInput(event) {
        const $fieldset = $(event.target).parents('fieldset').first();
        if (showLoginForm() && event.eventPhase) {
            pushGaEvent('REG_FORM_INTERACTION', `${event.target.name}Focus`);
        }
        $fieldset.addClass('active');
    },
    blurInput(event) {
        const $fieldset = $(event.target).parents('fieldset').first();

        $fieldset.removeClass('active');
    },
    setNameFlow() {
        const nameFlow = getNameFlow();

        if (nameFlow === 'generate') {
            return;
        }

        this.model.validators.push(
            nameRequiredValidator,
            namePatternValidator,
            nameMinLengthValidator,
            nameMaxLengthValidator,
            nameServerPrevalidation
        );
    },
    setPasswordFlow() {
        const passwordFlow = getPasswordFlow();

        if (passwordFlow === 'no_repeat') {
            this.model.validators.push(
                passwordRequiredValidator,
                passwordMinLengthValidator,
                passwordMaxLengthValidator,
                passwordPatternValidator,
                passwordMatchesLoginValidator,
                passwordMatchesNameValidator
            );
            return;
        } else if (passwordFlow === 'generate') {
            return;
        }

        this.model.validators.push(
            passwordRequiredValidator,
            passwordMinLengthValidator,
            passwordMaxLengthValidator,
            passwordPatternValidator,
            passwordMismatchValidator,
            passwordMatchesLoginValidator,
            passwordMatchesNameValidator,
            repasswordRequiredValidator
        );
    },
    updateView(view) {
        if (this.view !== view && Object.values(REGISTRATION_FORM_VIEWS).includes(view)) {
            this.view = view;

            switch (view) {
                case REGISTRATION_FORM_VIEWS.parental_email: {
                    this.$form.trigger(BIRTHDATE_EVENTS.save);
                    Application.trigger(ERROR_BLOCK_EVENTS.hide);
                    this.showParentalEmailView();
                    break;
                }

                case REGISTRATION_FORM_VIEWS.main:
                default:
                    this.showMainView();
                    break;
            }
        }
    },
    showParentalEmailView() {
        const regFormContainer = $('#registration-form', this.$el);
        const formSubmitButtonContainer = $('button[type=submit]', regFormContainer).parent();
        const formElements = regFormContainer.children('fieldset:visible').not(formSubmitButtonContainer);

        this.hiddenElements = formElements;

        formElements.addClass('hidden');
        this.$form.trigger(PARENTAL_EMAIL_FIELD_EVENTS.show);
        Application.trigger(REGISTRATION_VIEW_EVENTS.EXTERNAL_VIEW.hide);
    },
    showMainView() {
        this.$form.trigger(PARENTAL_EMAIL_FIELD_EVENTS.hide);
        this.$form.trigger(BIRTHDATE_EVENTS.render);
        Application.trigger(REGISTRATION_VIEW_EVENTS.EXTERNAL_VIEW.show);
        this.hiddenElements.removeClass('hidden');
    },
    setExternalBirthdate(externalBirthdate) {
        this.externalBirthdateData = externalBirthdate;
    }
});


export default BaseRegistrationFormView;
export { PREVENT_PREVALIDATE_KEYS };
