import {
    _, $
} from 'wgnet-awesomejs';
import BaseComponent from 'wgnet-awesomejs/base/component';
import template from './input.html';

const BaseInput = BaseComponent.extend({
    template,
    fieldName: 'input',
    afterInitialize() {
        const setFocusEvent = `${this.fieldName}.set.focus`;
        const updateValueEvent = `${this.fieldName}.update.value`;

        this.$form = this.$el.parents('form');
        this.$input = this.$el.find('input');
        this.$fieldset = this.$el;
        this.$form.on(updateValueEvent, _.bind((event, data) => {
            this.$input.val(data.value);
        }, this));
        this.$form.on(setFocusEvent, _.bind(() => {
            this.$input.trigger('focus');
        }, this));
        if (this.data.autofocus) {
            const gameModificatorIsWows = _.extract('Settings.Registration.gameModificatorIsWows', false);
            const isMobile = _.extract('Settings.Mobile.isMobile', false);

            if (!gameModificatorIsWows || !isMobile) {
                this.$input.trigger('focus');
            }
        }
    },
    getContext(context = {}) {
        context.fieldName = this.fieldName;
        return BaseComponent.prototype.getContext.call(this, context);
    },
    render(...args) {
        const $rendered = $(BaseComponent.prototype.render.call(this, args));
        this.$el.replaceWith($rendered);
        this.$el = $rendered;
        this.el = $rendered[0];
        return $rendered;
    }
});

export default BaseInput;
