import {
    _
} from 'wgnet-awesomejs';

import BaseModel from 'wgnet-awesomejs/base/model';

const regOptions = _.extract('Settings.Registration.registrationOptions', {});

const Model = BaseModel.extend({
    save(attr, options = {}) {
        const success = options.success;
        const error = options.error;
        const context = options.context;

        this.set('form_token', _.extract('Settings.Registration.formTokenRaw'));

        BaseModel.prototype.save.call(this, attr, _.extend(options, {
            success: (model, result) => {
                if (success) {
                    success.call(context || this, result);
                }
            },
            error: (model, result) => {
                if (error) {
                    error.call(context || this, result);
                }
            },
            context: this
        }));
    },
    validate(attrs, options = {}) {
        const validationErrors = BaseModel.prototype.validate.call(this, attrs, options);
        if (options.presaveValidate && validationErrors && validationErrors.strict_login) {
            const keys = _.keys(validationErrors);
            let hasError;
            let hasStrict;
            for (let i = keys.length - 1; i >= 0; i -= 1) {
                const error = validationErrors[keys[i]];
                if (keys[i] === 'strict_login' && !options.ignoreLoginStrict) {
                    hasStrict = true;
                } else if (_.isString(error[0])) {
                    hasError = true;
                }
            }
            if (hasError) {
                return validationErrors;
            }
            if (hasStrict) {
                this.trigger('login.strict.error', validationErrors.strict_login);
                return validationErrors;
            }
            return false;
        }
        return validationErrors;
    },
    set(attrs, options) {
        if (_.isObject(attrs) && !_.isEmpty(attrs)) {
            if (!attrs.eula) {
                attrs.eula = false;
            }
            attrs.consents = [].concat(
                attrs.eula ? regOptions.consentsImplicitList : [],
                attrs.subscr ? regOptions.consentsExplicitList : []
            );
        }
        return BaseModel.prototype.set.call(this, attrs, options);
    }
});

export default Model;
