import $ from 'jquery';
import URI from 'urijs';
import '@babel/polyfill';

import Application, {
    _
} from 'wgnet-awesomejs';
import BaseView from 'wgnet-awesomejs/base/view';
import mobile from 'wgnet-awesomejs/lib/mobile';
import initializeDataLayer from 'registration/js/lib/googletagmanager/datalayer';

import getdataForm from 'registration/templates/base/getdata_form.html';
import gettokenForm from 'registration/templates/base/gettoken_form.html';
import pushGaEvent from 'registration/js/lib/googletagmanager/events';
import pushGaEventNew, { GTM_EVENTS } from 'registration/js/lib/googletagmanager/new_events';
import {
    showLoginForm, openLoginOnInit
} from 'registration/js/lib/get_splittest_flow';
import initTooltip from 'registration/js/base/tooltip';
import {
    ERROR_BLOCK_EVENTS,
    TABS_EVENTS,
    TABS_STATE,
} from './constants';

// for work old library
window.$ = $;
window.jQuery = $;
window._ = _;
window.Application = Application;
window.URI = URI;

const enableSocialNetwork = () => {
    const providers = _.extract('Settings.Registration.externalServices.providers', []);
    const disableSocialButtons = URI.parseQuery(window.location.search).social_buttons === '0';
    const prefilledEmail = _.extract('Settings.Registration.registrationOptions.email');
    return !(prefilledEmail || disableSocialButtons) && providers.length > 0;
};

if (mobile().useMobile) {
    window.Settings.update({
        messages: _.extract('Settings.mobile_messages'),
        Geowidgets: {
            popupEnable: null,
            POPUP_ENABLED: null
        }
    });
}

if (mobile().useTouchDevice) {
    window.document.body.classList.add('page__touch');
}

window.Settings.update({
    cssDirection: $('body').css('direction')
});

const BaseRegistrationView = BaseView.extend({
    template() {},
    NewBasicFormView: BaseView,
    ExternalServiceView: BaseView,
    LoginExternalServiceView: BaseView,
    LoginBasicFormView: BaseView,
    tabState: openLoginOnInit() ? TABS_STATE.LOGIN : TABS_STATE.REG,
    beforeInitialize(...args) {
        if (showLoginForm()) {
            const registrationSettings = window.Settings.Registration;

            if (!registrationSettings.gameModificatorIsWows) {
                this.$el.addClass('b-content__double');
            }

            $('body').on('click a', this.onLinkClick.bind(this));
        }
        BaseView.prototype.beforeInitialize.call(this, ...args);
    },
    events: {
        'click .js-registration-tab-switch': 'onTabSwitch',
        'click .js-recovery-link a': 'onRecoveryClick',
        'click .js-switch-to-reg': 'onSwitchToReg'
    },
    onSwitchToReg(event) {
        event.preventDefault();
        this.loginExternalView.switchToReg();
    },
    async onLinkClick(event) {
        let link = event.target;
        if (link.nodeName !== 'A') {
            link = undefined;
            if (event.originalEvent.path) {
                link = event.originalEvent.path.find(node => node.nodeName === 'A');
            }
        }
        if (!link) {
            return;
        }
        if (link.href && link.href.indexOf(document.location.href) === -1) {
            event.preventDefault();
            await pushGaEvent('OUTBOUND', 'click', link.href);
            if (link.target) {
                window.open(link.href, link.target);
            } else {
                document.location.href = link.href;
            }
        }
    },
    setClass(el, className, state) {
        const $el = this.$(el);
        const method = state ? 'addClass' : 'removeClass';
        return $el[method](className);
    },
    async onRecoveryClick(event) {
        event.preventDefault();
        const el = event.currentTarget;
        const url = el.getAttribute('href');

        pushGaEventNew({ event: GTM_EVENTS.RECOVERY_LINK_CLICK });

        if (showLoginForm()) {
            await pushGaEvent('RECOVERY_INTERACTION', 'linkClick');
        }
        window.location.href = url;
    },
    onTabSwitch(event, tab) {
        if (event) {
            event.preventDefault();
        }
        if (tab === this.tabState) {
            return;
        }

        const stateDirection = TABS_EVENTS[this.tabState];

        pushGaEvent('FORM_STATE', stateDirection);
        pushGaEventNew({ event: GTM_EVENTS.FORM_STATE_CHANGE[stateDirection] });

        Application.trigger(ERROR_BLOCK_EVENTS.hide);

        this.tabState = tab || (1 - this.tabState);
        this.setClass('.js-registration-login_tab', 'hidden', this.tabState !== TABS_STATE.LOGIN);
        this.setClass('.js-registration-reg_tab', 'hidden', this.tabState !== TABS_STATE.REG);
        this.$(
            this.tabState === TABS_STATE.LOGIN ? '.js-registration-reg_tab' : '.js-registration-login_tab'
        ).trigger('__all__.valid', {});
        const activeForm = this.tabState === TABS_STATE.LOGIN ? 'login' : 'reg';
        const focusInputSelector = `.js-registration-${activeForm}_tab input[name=login]`;
        this.$(focusInputSelector).focus();
    },
    afterInitialize(options) {
        this.enableSocialNetwork = enableSocialNetwork();
        this.render();
        initTooltip(this.el);
        this.formView = new this.NewBasicFormView({
            el: '.js-registration-form-wrapper',
            parent: this,
            mobileDetect: options.mobileDetect
        });
        if (this.enableSocialNetwork) {
            this.externalView = new this.ExternalServiceView({
                el: '.js-registration-external-services',
                layout: null,
                parent: this
            });
        }
        if (showLoginForm()) {
            if (typeof window.dataLayer !== 'undefined') {
                const game = _.extract('Settings.Registration.gameModificator', 'none');
                window.dataLayer.push({
                    RegFormType: `New-2019-${game.toUpperCase()}-Reg-Login-Form-v1`
                });
            }
            this.loginFormView = new this.LoginBasicFormView({
                el: '.js-registration-login-form-wrapper',
                parent: this
            });
            if (this.enableSocialNetwork) {
                this.loginExternalView = new this.LoginExternalServiceView({
                    el: '.js-registration-login-external-services',
                    layout: null,
                    parent: this
                });
            }
        }
    },
    getContext(context = {}) {
        context.getdataForm = getdataForm(window);
        context.gettokenForm = gettokenForm(window);

        const result = BaseView.prototype.getContext.call(this, context);
        result.push({
            socialNetworksEnabled: this.enableSocialNetwork,
            loginShow: showLoginForm(),
            tabState: {
                login: this.tabState === TABS_STATE.REG && 'hidden',
                registration: this.tabState === TABS_STATE.LOGIN && 'hidden'
            }
        });
        return result;
    },
    triggerCommonError(form, event, data) {
        this.onTabSwitch(null, form);
        const $form = form === TABS_STATE.LOGIN
            ? this.loginFormView.$form
            : this.formView.$form;
        if ($form) {
            $form.trigger(`__all__.${event}`, data);
            Application.trigger(ERROR_BLOCK_EVENTS.show, data);
        }
    },
    async showForm(...args) {
        await this.formView.show(...args);
    },
    hideForm(...args) {
        this.formView.hide(...args);
    },
});
initializeDataLayer();
export default BaseRegistrationView;
