import $ from 'jquery';
import Application, {
    _,
    Backbone
} from 'wgnet-awesomejs';
import pushGaEvent from 'registration/js/lib/googletagmanager/events';
import { showLoginForm } from 'registration/js/lib/get_splittest_flow';

const { View } = Backbone;
let console = { log: () => {} };
const debugLog = window.Settings.DEBUG_LOG || false;
const datalayerviewEnabled = _.extract('Settings.datalayerviewEnabled');

if (debugLog && window.console) {
    console = window.console;
}
const DataLayerView = View.extend({
    initialize() {
        this.clientDownloadEventName = 'client-download';
        this.portalLinkEventName = 'portal-link';
    },
    clientDownloadEvent(game, platform) {
        const label = `${game}-${platform}`;
        this.sendEvent(this.clientDownloadEventName, label);
    },
    portalLinkEvent(game) {
        this.sendEvent(this.portalLinkEventName, game);
    },
    sendEvent(eventName, eventLabel) {
        const dataLayer = window.dataLayer;
        if (typeof dataLayer !== 'undefined') {
            const eventData = { event: eventName, eventLabel };
            console.log(eventData);
            dataLayer.push(eventData);
        }
    },
    async onLinkClick(event) {
        const link = event.currentTarget;
        if (link.href && link.href.indexOf(document.location.href) === -1) {
            event.preventDefault();
            await pushGaEvent('OUTBOUND', 'click', link.href);
            document.location.href = link.href;
        }
    }
});
const initializeDataLayer = function initializeDataLayer() {
    if (!datalayerviewEnabled) {
        return null;
    }
    const dataLayerView = new DataLayerView();
    Application.Views.DataLayerView = dataLayerView;
    const locations = ['header', 'footer'];
    $(() => _.each(locations, (linkLocation) => {
        const downloadClass = `.js-gtm-${linkLocation}-client-download`;
        const portalClass = `.js-gtm-${linkLocation}-portal-link`;

        $(downloadClass).click(async (event) => {
            const $el = $(event.currentTarget);
            const game = `${linkLocation}-${$el.data('game')}`;
            const label = $el.data('download-key');
            dataLayerView.clientDownloadEvent(game, label);
            if (showLoginForm()) {
                await dataLayerView.onLinkClick(event);
            }
        });
        $(portalClass).click(async (event) => {
            const gameKey = $(event.currentTarget).data('game');
            const game = `${linkLocation}-${gameKey}`;
            dataLayerView.portalLinkEvent(game);
            if (showLoginForm()) {
                await dataLayerView.onLinkClick(event);
            }
        });
    }));
    return dataLayerView;
};

export default initializeDataLayer;
