import $ from 'jquery';
import BaseComponent from 'wgnet-awesomejs/base/component';
import Application, {
    _
} from 'wgnet-awesomejs';
import mobile from 'wgnet-awesomejs/lib/mobile';

import { bonusCodeOptions } from 'registration/js/lib/fieldsOptions';


const MSG_PREFIX = 'Settings.messages.registration';
const ERROR_PREFIX = 'Settings.errors.registration';
const registrationOptions = _.extract('Settings.Registration.registrationOptions', {});

const GTM_EVENT_TYPES = Object.assign({
    error: 'Error',
    tipEmailSoftShow: 'Tip soft hint show email',
    tipEmailSoftUse: 'Tip soft hint use email',
    tipEmailPopupShow: 'Tip popup show email',
    tipEmailPopupConfirm: 'Tip popup confirm email',
    tipEmailPopupCancel: 'Tip popup cancel email',
    tipNicknameShow: 'Tip show nickname',
    tipNicknameUse: 'Tip use nickname'
}, _.extract('Settings.Registration.GOOGLE_ANALYTICS_FORM_EVENT_TYPES', {}));

const FIELD_ERROR_EVENTS = {
    login: 'wgnet.registration.login',
    bonus_code: 'wgnet.registration.bonus_code'
};

const SUGGESTED_FIELDS = [
    'login',
    'name'
];

const softTipGaActions = {
    login: {
        show: GTM_EVENT_TYPES.tipEmailSoftShow,
        use: GTM_EVENT_TYPES.tipEmailSoftUse
    },
    name: {
        show: GTM_EVENT_TYPES.tipNicknameShow,
        use: GTM_EVENT_TYPES.tipNicknameUse
    }
};

const BaseErrorField = BaseComponent.extend({
    name: 'error-field',
    template() {
        return '';
    },
    errorClass: 'error',
    correctClass: null,
    iconModifierPrefix: 'form-input_validate-ico__',

    highlightLoginTemplate() {
        return '';
    },
    beforeInitialize(options = {}) {
        const iconPrfx = this.iconModifierPrefix;

        this.errorClass = options['wrapper-error-class'] || this.errorClass;
        this.correctClass = options['wrapper-correct-class'] || this.correctClass;

        this.$iconAllState = `${iconPrfx}error ${iconPrfx}correct ${iconPrfx}warning`;
        this.fieldName = options.fieldName;
        this.forceShowHint = options.forceShowHint;
        this.dataLayer = window.dataLayer;
    },
    initParentElements() {
        this.$body = $('body');
        this.$form = this.$el.parents('.js-form');
        this.$fieldset = this.$el.parents('.js-fieldset');
        this.initElements();
    },
    initElements() {
        this.$errorStateWrapper = this.$fieldset.find('.js-error-state-wrapper');
        this.$icon = this.$fieldset.find('.js-validate-ico');

        let inputSelector = 'input';
        if (this.$fieldset[0] && this.$fieldset[0].dataset && this.$fieldset[0].dataset.input) {
            inputSelector = this.$fieldset[0].dataset.input;
        }
        this.$input = this.$fieldset.find(inputSelector);
    },
    initEvents() {
        const errorEvent = `${this.fieldName}.error`;
        const validEvent = `${this.fieldName}.valid`;
        const clearEvent = `${this.fieldName}.clear`;
        const valueUpdateEvent = `${this.fieldName}.value.update`;

        this.delegateEvents({
            'mousedown .js-registration-value-replace': 'replaceValue'
        });
        if (this.fieldName) {
            this.$form.on(errorEvent, _.bind(this.showErrors, this));
            this.$form.on(validEvent, _.bind(this.showValid, this));
            this.$form.on(clearEvent, () => {
                this.hideError();
                this.hideIcon();
            });
            this.$form.on(valueUpdateEvent, _.bind(this.onValueUpdate, this));
        }
    },
    afterInitialize() {
        const emailHighlightEnabled = registrationOptions.suggestionsHighlightDomain;
        this.initParentElements();
        this.initEvents();
        if (!emailHighlightEnabled) {
            this.addHighlight = () => {};
        } else if (this.fieldName === 'login') {
            this.$input.on('keyup', () => {
                if (this.getFieldState('suggested-state')) {
                    this.addHighlight(this.$input.val());
                }
            });
        }
    },

    handleErrorStateChange(error) {
        if (this.correctClass) {
            const correctClassActive = Boolean(this.$input.val() && !error);
            // add "correct" class if input has value and is not in error state
            this.$errorStateWrapper.toggleClass(this.correctClass, correctClassActive);
        }
    },

    setFieldState(key, value) {
        const changed = value !== this.getFieldState(key);
        const inputIsEmpty = !this.$input.val();

        if (key === 'error-state') {
            this.handleErrorStateChange(value);
        }

        if (inputIsEmpty) {
            this.$fieldset.data(key, null);
            this.hideIcon();
            return;
        }
        if (changed) {
            this.$fieldset.data(key, value);
            this.setIcon();
        }
    },
    getFieldState(key) {
        return this.$fieldset.data(key);
    },
    setIcon() {
        if (!this.$input.val()) {
            this.hideIcon();
            return false;
        }
        if (this.fieldName === 'bonus_code' && bonusCodeOptions.isPrepopulated()) {
            return false;
        }
        let iconState;
        iconState = this.getFieldState('suggested-state') ? 'warning' : 'correct';
        iconState = this.getFieldState('error-state') ? 'error' : iconState;
        this.$icon.removeClass(this.$iconAllState);
        this.$icon.addClass(`${this.iconModifierPrefix}${iconState}`);
        this.$icon.show();
        return iconState;
    },
    hideIcon() {
        this.$icon.removeClass(this.$iconAllState);
        this.$icon.hide();
    },
    onPromiseError(result = {}) {
        let errorMsg;

        if (result.suggestions) {
            const suggestions = result.suggestions.domains ||
                                _.map(result.suggestions, suggestion => ({ full: suggestion }));

            const MSG_KEY = `${MSG_PREFIX}-${result.field}-suggested`;
            const infoMsg = _.extract(MSG_KEY);
            const shortInfoMsg = _.extract(`${MSG_KEY}_short`);

            _.last(suggestions).last = true;

            if (result.error) {
                const ERR_MSG_KEY = `${ERROR_PREFIX}-${result.field}-${result.error}`;
                errorMsg = _.extract(ERR_MSG_KEY, '');
            }
            this.showSuggested(result, {
                suggestions,
                hasRestricted: _.some(suggestions, s => s.restricted),
                hasSuggestion: true,
                errorMsg,
                shortInfoMsg,
                infoMsg
            });
        } else if (result.error) {
            this.showErrors({}, { errors: [result.error], field: this.fieldName });
        } else {
            this.hideSuggested();
            this.hideError();
            this.setIcon();
        }
        return result;
    },
    showSuggested(result, options) {
        clearTimeout(this.defferedStateIcon);
        this.setFieldState('suggested-state', true);
        if (!((result.field === 'login' && !mobile().useMobile) || result.field === 'name')) {
            return;
        }

        this.renderErrors(options);
        if (result.field === 'login') {
            this.addHighlight(result.value);
        }
        if (!result.gaLogged) {
            result.gaLogged = true;
            this.sendTipGaEvent('show', result.field);
        }
    },
    sendTipGaEvent(type, field) {
        if (typeof this.dataLayer !== 'undefined') {
            const eventActions = softTipGaActions[this.fieldName];

            if (eventActions) {
                const eventAction = eventActions[type];

                this.dataLayer.push({
                    event: 'GAevent',
                    eventLabel: `${field}_suggested`,
                    eventCategory: 'Form messages',
                    eventAction
                });
            }
        }
    },
    hideSuggested() {
        this.setFieldState('suggested-state', false);
        this.removeHighlight();
    },
    getErrorMessage(field, error) {
        const ERR_MSG_KEY = `Settings.errors.registration-${field}-${error}`;
        return _.extract(ERR_MSG_KEY, '');
    },
    getInfoMessage(field, error) {
        const ERR_MSG_KEY = `Settings.messages.registration-${field}-${error}`;
        return _.extract(ERR_MSG_KEY, '');
    },
    // eslint-disable-next-line no-unused-vars
    showErrors(event = {}, result = {}) {
        let error = _.first(result.errors || []);
        clearTimeout(this.defferedStateIcon);
        if (_.isObject(error)) {
            error.then((res) => this.onPromiseError(res));
            error = undefined;
            if (_.contains(SUGGESTED_FIELDS, result.field) && this.$input.is(':focus')) {
                this.hideSuggested();
                this.hideError();
                this.hideIcon();
                this.defferedStateIcon = setTimeout(() => this.setIcon(), 1500);
            }
            return;
        }
        if (result.value) {
            this.replaceValue(result, true);
        }
        this.hideSuggested();
        if (!error) {
            this.hideError();
            return;
        }

        if (_.has(result, 'field') && _.has(FIELD_ERROR_EVENTS, result.field)) {
            Application.trigger(FIELD_ERROR_EVENTS[result.field], error);
        }
        const errorMsg = this.getErrorMessage(result.field, error);
        const shortInfoMsg = this.getInfoMessage(result.field, error);

        this.renderErrors({ errorMsg, shortInfoMsg });
        if (!result.prevalidate && typeof this.dataLayer !== 'undefined') {
            this.dataLayer.push({
                event: 'GAevent',
                eventLabel: `registration-${result.field}-${error}`,
                eventCategory: 'Form messages',
                eventAction: GTM_EVENT_TYPES.error
            });
        }
    },
    addHighlight(value) {
        if (!value) {
            return;
        }
        const valueParts = value.split('@');
        const highlight = {
            address: valueParts[0],
            domain: valueParts[1],
            full: value
        };
        this.$fieldset.find('.js-email-highlight-content')
            .html(this.highlightLoginTemplate(highlight));
        const $highlight = this.$fieldset.find('.js-email-highlight');
        $highlight.show();
        const $span = $highlight.find('span');
        if ($span.length > 1) {
            const spansWidth = $span[0].offsetWidth + $span[1].offsetWidth;
            if (spansWidth > $highlight[0].offsetWidth) {
                $highlight.hide();
            }
        }
    },
    removeHighlight() {
        this.$fieldset.find('.js-email-highlight-content').html('');
        this.$fieldset.find('.js-email-highlight').hide();
    },
    replaceValue(event = {}, notHideErrors) {
        this.$fieldset.find('.js-form-field-hint').addClass('hidden');
        const value = event.value || $(event.target).data('suggestion');

        this.$el.trigger(`suggestion.${this.fieldName}`, {
            suggestion_entered: this.$input.val().split('@')[1],
            suggestion_used: value.split('@')[1],
            suggestion_type: 'soft'
        });
        this.$input.val(value);
        if (notHideErrors) {
            return false;
        }
        this.setFieldState('suggested-state', false);
        this.sendTipGaEvent('use', this.fieldName);
        this.hideSuggested();
        this.hideError();
        return false;
    },
    renderErrors(error) {
        let renderError = error;
        if (this.data.noErrorMessage) {
            renderError = Object.assign({}, error, {
                errorMsg: null
            });
        }
        this.render(renderError);
        this.initElements();
        if (error.errorMsg || this.data.noErrorMessage) {
            this.setFieldState('error-state', true);
            this.$errorStateWrapper.addClass(this.errorClass);
        } else {
            this.setFieldState('error-state', false);
        }
    },
    hideHint({ relatedTarget }) {
        if (!$(relatedTarget).hasClass('js-prevent-hide-hint')) {
            this.$('.js-form-field-hint-error').addClass('hidden');
        }
        return false;
    },
    showHint() {
        if (this.getFieldState('error-state') || this.getFieldState('suggested-state')) {
            this.$('.js-form-field-hint-error').removeClass('hidden');
        }
    },
    onValueUpdate() {
        this.setFieldState('suggested-state', false);
        this.removeHighlight();
        this.hideError();
    },
    showValid() {
        if (!this.$input.val()) {
            this.setFieldState('suggested-state', false);
        }
        if (!this.getFieldState('suggested-state')) {
            this.hideError();
        }
    },
    hideError() {
        this.render();
        this.removeHighlight();
        this.setFieldState('error-state', false);
        this.$errorStateWrapper.removeClass(this.errorClass);
    }
});

export default BaseErrorField;
export {
    FIELD_ERROR_EVENTS,
    GTM_EVENT_TYPES
};
