import $ from 'jquery';
import {
    _,
} from 'wgnet-awesomejs';

import { getPasswordFlow } from 'registration/js/lib/get_field_flow';
import BaseInput from './input';

const BasePasswordField = BaseInput.extend({
    name: 'password-field',
    fieldName: 'password',
    descriptionText: (_.extract('Settings.messages.registration-password_description_text') || '')
        .replace(/%\(length\)s/g, _.extract('Settings.Registration.fields.password.min_length'))
        .replace(/"/g, '&quot;')
        .replace(/%\(bull\)s/g, '&bull;')
        .replace(/%\(br\)s/g, '<br/>'),

    afterInitialize(...args) {
        BaseInput.prototype.afterInitialize.apply(this, args);
        const passwordFlow = getPasswordFlow();

        if (passwordFlow !== 'default') {
            const $passwordField = this.$('input');
            const $passwordFieldWrapper = $passwordField.parent();
            const $passwordFieldSet = $passwordField.parents('fieldset');
            const $passwordEye = $('.js-password-eye', $passwordFieldSet);
            const passwordEyeBemBlock = $passwordEye.data('bem-block');
            const passwordEyeWrModifierShow = $passwordEye.data('bem-wrapper-modifier-show');
            const passwordEyeModifierShow = $passwordEye.data('bem-modifier-show');
            const passwordEyeShowClass = `${passwordEyeBemBlock}__${passwordEyeModifierShow}`;

            if (passwordFlow === 'no_repeat') {
                $passwordFieldWrapper.addClass(passwordEyeWrModifierShow);
                $passwordEye.removeClass('hidden');

                $passwordEye.on('click', _.bind(() => {
                    const currentType = $passwordField.attr('type');
                    const changeType = currentType === 'password' ? 'text' : 'password';

                    $passwordEye.toggleClass(passwordEyeShowClass);
                    $passwordField.attr('type', changeType);

                    // After changing field type, we must manually set focus to the last character
                    const hasValue = $passwordField.val() !== '';
                    if (hasValue) {
                        const element = $passwordField[0];

                        if (element.selectionStart || element.selectionStart === 0) {
                            const valueLength = element.value.length;

                            element.selectionStart = valueLength;
                            element.selectionEnd = valueLength;
                        }
                    }

                    $passwordField.focus();
                }, this));
            } else if (passwordFlow === 'generate') {
                $passwordFieldSet.hide();
            }
        }
    },

    getContext(context = {}) {
        context.descriptionText = this.descriptionText;
        return BaseInput.prototype.getContext.call(this, context);
    },
});

export default BasePasswordField;
