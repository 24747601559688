import BaseModel from 'wgnet-awesomejs/base/model';

function parseResponse(response) {
    return response.responseJSON || JSON.parse(response.responseText);
}

const signInExternalData = new Map();

function setSignInExternalData(external, data) {
    signInExternalData.set(external, data);
}
function getSignInExternalData(external) {
    return signInExternalData.get(external);
}

const BasicExternalModel = BaseModel.extend({
    initialize(attr = {}, options = {}) {
        BaseModel.prototype.initialize.call(this, attr, options);
        this.oauthAuthorizeUrl = options.oauthAuthorizeUrl;
        this.openIdAuthorizeUrl = options.openIdAuthorizeUrl;
        this.getDataUrl = options.getDataUrl;
        this.openIdProviders = options.openIdProviders || [];
    },
    getAuthorizeUrl(provider) {
        const isOpenId = this.openIdProviders.indexOf(provider) > -1;
        return isOpenId ? this.openIdAuthorizeUrl : this.oauthAuthorizeUrl;
    },
    goToUrl(url) {
        window.location.href = url;
    },
    getToken(provider) {
        this.set('external', provider);
        return new Promise((resolve, reject) => {
            this.save({}, {
                url: this.getAuthorizeUrl(provider),
                success: (model, result) => {
                    if (result.success_url) {
                        this.goToUrl(result.success_url);
                        return;
                    }
                    resolve(result);
                },
                error(model, xhr) {
                    reject(parseResponse(xhr));
                }
            });
        });
    },
    getData() {
        return new Promise((resolve, reject) => {
            this.fetch({
                success: (model, result) => {
                    resolve(result);
                },
                error(model, xhr) {
                    reject(parseResponse(xhr));
                },
                url: this.getDataUrl,
                type: 'POST'
            });
        });
    }
});

export {
    setSignInExternalData,
    getSignInExternalData,
};

export default BasicExternalModel;
