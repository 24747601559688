import { $, _ } from 'wgnet-awesomejs';
import { Model } from 'backbone';
import BaseComponent from 'wgnet-awesomejs/base/component';
import mobile from 'wgnet-awesomejs/lib/mobile';

import {
    countryCodeOptions as _countryCodeOptions
} from 'registration/js/lib/fieldsOptions';

const FieldState = Model.extend({
    constructor(attributes, { countryCodeOptions }) {
        const attrs = Object.assign({
            opened: false,
            focused: false,
            countryCode: '',
        }, attributes || {});
        if (!countryCodeOptions.isValidCountryCode(attrs.countryCode)) {
            attrs.countryCode = '';
        }
        Model.call(this, attrs);
        this.countryCodeOptions = countryCodeOptions;
    },

    getCountryName() {
        const name = this.countryCodeOptions.getCountryName(this.attributes.countryCode);
        return name || '';
    },

    getCountryFlagIcon() {
        if (!this.attributes.countryCode) {
            return 'all';
        }
        return this.attributes.countryCode.toLowerCase();
    }
});

const CountryCodeField = BaseComponent.extend({
    name: 'country-code-field',
    fieldName: 'country_code',
    label: _.extract('Settings.messages.registration-country_code-label'),
    placeholder: _.extract('Settings.messages.registration-country_code-placeholder'),
    template: () => '<span>The template must be defined for the Country component</span>',
    countryCodeOptions: _countryCodeOptions,
    isMobile: mobile().useMobile,

    elementsMap: {
        root: '.js-country_code-root',
        selectorWrapper: '.js-country_code-selector',
        input: '.js-country_code-selector_input',
        countryFlag: '.js-country-flag',
        selectorTouch: '.js-country_code-selector_touch',
        menuItem: '.js-country_code-dropdown-menu_item',
    },

    classModifiersMap: {
        opened: 'country-selector__open',
        focused: 'country-selector__focus',
        activeItem: 'dropdown-menu_item__active',
    },

    beforeInitialize(options = {}) {
        BaseComponent.prototype.beforeInitialize.call(this, options);
        this.state = new FieldState({
            countryCode: options['data-country_code'],
        }, { countryCodeOptions: this.countryCodeOptions });
    },

    afterInitialize(options = {}) {
        BaseComponent.prototype.afterInitialize.call(this, options);

        this.$form = this.$el.parents('form');
        this.$fieldset = this.$el;
        this.$root = this.$el.find(this.elementsMap.root);
        this.$input = this.$el.find(this.elementsMap.input);
        this.$selectedIcon = this.$el.find(this.elementsMap.countryFlag);
        this.$selectorTouch = this.$el.find(this.elementsMap.selectorTouch);
        this.$menuItems = this.$el.find(this.elementsMap.menuItem);

        this.$form.on(`${this.fieldName}.update.value`, (event, data) => {
            let countryCode = data && data.value;
            countryCode = this.countryCodeOptions.isValidCountryCode(countryCode) ? countryCode : '';
            if (this.state.get('countryCode') !== countryCode) {
                this.state.set({ countryCode });
            }
        });

        this.$form.on(`${this.fieldName}.set.focus`, () => {
            this.state.set({ focused: true });
        });

        this.delegateEvents({
            [`click ${this.elementsMap.selectorWrapper}`]: () => {
                this.state.set({ opened: !this.state.get('opened') });
            },
            [`focus ${this.elementsMap.input}`]: () => {
                this.state.set({ focused: true });
            },
            [`blur ${this.elementsMap.input}`]: () => {
                this.state.set({ focused: false });
            },
            [`change ${this.elementsMap.selectorTouch}`]: (e) => {
                const value = e.target.selectedOptions[0].value;
                this.$form.trigger(`${this.fieldName}.update.value`, { value });
                this.state.set({ countryCode: value, opened: false });
            },
            [`click ${this.elementsMap.menuItem}`]: (e) => {
                e.stopPropagation();
                const value = e.currentTarget.dataset.value;
                this.$form.trigger(`${this.fieldName}.update.value`, { value });
                this.state.set({ countryCode: value, opened: false });
            },
        });

        if (!this.isMobile) {
            document.addEventListener('mouseup', (e) => {
                // if clicked outside selector, close menu
                if (!$.contains(this.$el[0], e.target)) {
                    this.state.set({ opened: false });
                }
            });
        }

        this.listenTo(this.state, 'change', (e) => {
            this.updateViewState(e.changed);
        });
        this.updateViewState(this.state.attributes);
    },

    updateViewState(stateChanges) {
        const changes = Object.entries(stateChanges);
        for (let i = 0; i < changes.length; i += 1) {
            const [key, value] = changes[i];
            switch (key) {
                case 'focused': {
                    this.$root.toggleClass(this.classModifiersMap.focused, value);
                    break;
                }
                case 'opened': {
                    this.$root.toggleClass(this.classModifiersMap.opened, value);
                    break;
                }
                case 'countryCode': {
                    const selectedIconEl = this.$selectedIcon[0];
                    if (selectedIconEl) {
                        const classes = Array.from(selectedIconEl.classList);
                        for (let j = 0; j < classes.length; j += 1) {
                            if (classes[j].startsWith('country-flag__')) {
                                selectedIconEl.classList.remove(classes[j]);
                                break;
                            }
                        }
                        selectedIconEl.classList.add(
                            `country-flag__${this.state.getCountryFlagIcon()}`,
                        );
                    }

                    if (this.$input[0].tagName.toLowerCase() === 'input') {
                        this.$input.val(value ? this.state.getCountryName() : '');
                    } else {
                        this.$input.text(this.state.getCountryName() || this.placeholder);
                    }

                    this.$selectorTouch.val(value);

                    if (this.$menuItems.length > 0) {
                        this.$menuItems.removeClass(this.classModifiersMap.activeItem);
                        if (value) {
                            const selectedEl = Array.from(this.$menuItems)
                                .find(el => el.dataset.value === value);
                            if (selectedEl) {
                                selectedEl.classList.add(this.classModifiersMap.activeItem);
                            }
                        }
                    }

                    this.$form.trigger('form.prevalidate');
                    break;
                }
                default:
                    break;
            }
        }
    },

    getContext(...args) {
        const result = BaseComponent.prototype.getContext.apply(this, args);
        result.push({
            fieldName: this.fieldName,
            label: this.label,
            placeholder: this.placeholder,
            selectedText: this.state.getCountryName(),
            selectedIcon: this.state.getCountryFlagIcon(),
            options: this.countryCodeOptions.options,
            isMobile: mobile().useMobile,
        });

        return result;
    },

    render(...args) {
        const $rendered = $(BaseComponent.prototype.render.call(this, args));
        this.$el.replaceWith($rendered);
        this.$el = $rendered;
        this.el = $rendered[0];
        return $rendered;
    },
});

export default CountryCodeField;
